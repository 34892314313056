var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-sidebar w3-sidebar w3-bar-block w3-animate-left",staticStyle:{"direction":"ltr"},attrs:{"id":"mySidebar"}},[_c('div',{staticClass:"nav nav-tabs custom-sidebar-nav-tabs",attrs:{"id":"sidebar-tabs","role":"tablist"}},[_c('router-link',{staticClass:"w3-bar-item w3-button custom-bar-item custom-active",attrs:{"to":"/"}},[_c('div',{staticClass:"custom-sidebar-heading",on:{"click":_vm.w3_close}},[_c('div',{staticClass:"custom-row"},[_c('div',{staticClass:"custom-sidebar-icons"},[_c('b-icon',{attrs:{"icon":"house-door"}})],1),_c('div',{staticClass:"custom-sidebar-text"},[_c('span',[_vm._v(_vm._s(_vm.$t('Main.Home')))])])])])]),_c('router-link',{staticClass:"w3-bar-item w3-button custom-bar-item custom-active",attrs:{"to":{ name: 'PartnersAndFunding',
                            params: { type: 1, searchString:0 }
                          }}},[_c('div',{staticClass:"custom-sidebar-heading",on:{"click":_vm.w3_close}},[_c('div',{staticClass:"custom-row"},[_c('div',{staticClass:"custom-sidebar-icons"},[_c('b-icon',{attrs:{"icon":"person-check"}})],1),_c('div',{staticClass:"custom-sidebar-text"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Main.BusinessServices')))])])])])]),_c('router-link',{staticClass:"w3-bar-item w3-button custom-bar-item custom-active",attrs:{"to":{ name: 'PartnersAndFunding',
                            params: { type: 2, searchString:0 }
                          }}},[_c('div',{staticClass:"custom-sidebar-heading",on:{"click":_vm.w3_close}},[_c('div',{staticClass:"custom-row"},[_c('div',{staticClass:"custom-sidebar-icons"},[_c('b-icon',{attrs:{"icon":"grid"}})],1),_c('div',{staticClass:"custom-sidebar-text"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Main.Investment')))])])])])]),_c('router-link',{staticClass:"w3-bar-item w3-button custom-bar-item custom-active",attrs:{"to":{ name: 'PartnersAndFunding',
                            params: { type: 4, searchString:0 }
                          }}},[_c('div',{staticClass:"custom-sidebar-heading",on:{"click":_vm.w3_close}},[_c('div',{staticClass:"custom-row"},[_c('div',{staticClass:"custom-sidebar-icons"},[_c('b-icon',{attrs:{"icon":"grid"}})],1),_c('div',{staticClass:"custom-sidebar-text"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Main.ExpertPanel')))])])])])]),_c('a',{staticClass:"w3-bar-item w3-button custom-bar-item custom-active",attrs:{"href":"https://harshehar.com/#/directory","target":"_blank"}},[_c('div',{staticClass:"custom-sidebar-heading",on:{"click":_vm.w3_close}},[_c('div',{staticClass:"custom-row"},[_c('div',{staticClass:"custom-sidebar-icons"},[_c('b-icon',{attrs:{"icon":"grid"}})],1),_c('div',{staticClass:"custom-sidebar-text"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Main.SocialRegister')))])])])])]),_c('router-link',{staticClass:"w3-bar-item w3-button custom-bar-item custom-active",attrs:{"to":"/contact-us"}},[_c('div',{staticClass:"custom-sidebar-heading",on:{"click":_vm.w3_close}},[_c('div',{staticClass:"custom-row"},[_c('div',{staticClass:"custom-sidebar-icons"},[_c('b-icon',{attrs:{"icon":"grid"}})],1),_c('div',{staticClass:"custom-sidebar-text"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Main.ContactUs')))])])])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
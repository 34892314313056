<template>
  <div id="main-container" :key="componentKey">

    <!-- Banner Section For Web Start -->
    <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <section class="webSection  custom-landing-banner-section1">
            <div class="custom-banner-background-overlay"></div>
            <div class="custom-section">
              <div class="custom-row-wrap">
                <div class="custom-row-element ">
                  <div class="custom-row-element-wrap ">
                    <div class="row custom-row-section ">
                      <div class="col-lg-12 custom-column-banner-heading custom-carousel-left-z-index text-center ">
                        <div>
                          <h4><b-badge  class=" pt-1 pb-1 pl-3 pr-3 font-weight-normal" style="background-color: limegreen">Culture</b-badge></h4>
                        </div>
                        <div class="custom-banner-heading">
                          <span>Badshahi Mosque is an important remarkable of Mughal Architecture</span>
                        </div>

                        <!--                        <div class="row mt-4 w3-animate-bottom">
                                                  <div class="col-lg-6">

                                                    <div class="custom-form-submit-btn">

                                                      <router-link :to="{name: 'SignUp'}"
                                                                   class="btn custom-btn-search custom-employee-btn my-2 ">
                                                    <span class="custom-btn-more-text font-weight-bold">
                                                      <i class="fas fa-plus-circle"></i>
                                                      Register As Employee
                                                    </span>
                                                      </router-link>
                                                    </div>
                                                  </div>
                                                  <div class="col-lg-6">
                                                    <div class="custom-form-submit-btn">

                                                      <router-link :to="{name: 'SignUp'}"
                                                                   class="btn custom-btn-search custom-employer-btn my-2">
                                                        <span class="custom-btn-more-text font-weight-bold">
                                                          <i class="fas fa-plus-circle"></i>
                                                          Register As Employer
                                                        </span>
                                                      </router-link>
                                                    </div>
                                                  </div>
                                                  &lt;!&ndash;                          <div class="col-lg-2"></div>&ndash;&gt;
                                                </div>-->
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="carousel-item">
          <section class="webSection custom-landing-banner-section2">
            <div class="custom-banner-background-overlay"></div>
            <div class="custom-section">
              <div class="custom-row-wrap">
                <div class="custom-row-element">
                  <div class="custom-row-element-wrap">
                    <div class="row custom-row-section ">
                      <div class="col-lg-12 custom-column-banner-heading custom-carousel-left-z-index ">
                        <div class="custom-banner-heading">
                          <span>Best Place To <span class="custom-text-color">Grow</span></span><br>
                          <span>Your Career</span>
                        </div>
                        <div class="custom-banner-p">
                          <p>Find Jobs, Employment and Career Opportunities</p>
                        </div>
                        <!--                        <div class="row mt-4 w3-animate-bottom">
                                                  <div class="col-lg-6">
                                                    <div class="custom-form-submit-btn">

                                                      <router-link :to="{name: 'SignUp'}"
                                                                   class="btn custom-btn-search custom-employee-btn my-2 ">
                                                    <span class="custom-btn-more-text font-weight-bold">
                                                      <i class="fas fa-plus-circle"></i>
                                                      Register As Employee
                                                    </span>
                                                      </router-link>
                                                    </div>
                                                  </div>
                                                  <div class="col-lg-6">
                                                    <div class="custom-form-submit-btn">

                                                      <router-link :to="{name: 'SignUp'}"
                                                                   class="btn custom-btn-search custom-employer-btn my-2 ">
                                                        <span class="custom-btn-more-text font-weight-bold">
                                                          <i class="fas fa-plus-circle"></i>
                                                          Register As Employer
                                                        </span>
                                                      </router-link>
                                                    </div>
                                                  </div>
                                                  &lt;!&ndash;                          <div class="col-lg-2"></div>&ndash;&gt;
                                                </div>-->
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="carousel-item">
          <section class="webSection custom-landing-banner-section3">
            <div class="custom-banner-background-overlay"></div>
            <div class="custom-section">
              <div class="custom-row-wrap">
                <div class="custom-row-element">
                  <div class="custom-row-element-wrap">
                    <div class="row custom-row-section ">
                      <div class="col-lg-12 custom-column-banner-heading custom-carousel-left-z-index ">
                        <div class="custom-banner-heading">
                          <span>Lets Start Your Job </span><br>
                          <span>With <span class="custom-text-color">SheMeanWork</span></span>
                        </div>
                        <div class="custom-banner-p">
                          <p>Find Jobs, Employment and Career Opportunities</p>
                        </div>
                        <!--                        <div class="row mt-4 w3-animate-bottom">
                                                  <div class="col-lg-6">
                                                    <div class="custom-form-submit-btn">

                                                      <router-link :to="{name: 'SignUp'}"
                                                                   class="btn custom-btn-search custom-employee-btn my-2 ">
                                                    <span class="custom-btn-more-text font-weight-bold">
                                                      <i class="fas fa-plus-circle"></i>
                                                      Register As Employee
                                                    </span>
                                                      </router-link>
                                                    </div>
                                                  </div>
                                                  <div class="col-lg-6">
                                                    <div class="custom-form-submit-btn">

                                                      <router-link :to="{name: 'SignUp'}"
                                                                   class="btn custom-btn-search custom-employer-btn my-2 ">
                                                        <span class="custom-btn-more-text font-weight-bold">
                                                          <i class="fas fa-plus-circle"></i>
                                                          Register As Employer
                                                        </span>
                                                      </router-link>
                                                    </div>
                                                  </div>

                                                </div>-->
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <a class="carousel-control-prev custom-carousel-prev" href="#carouselExampleControls" role="button"
         data-slide="prev">
        <span class="carousel-control-prev-icon " aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next custom-carousel-next" href="#carouselExampleControls" role="button"
         data-slide="next">
        <span class="carousel-control-next-icon " aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>

    <!--    <div style="height: 1000px">
          <SlidesComponent>
            <rl-carousel-slide class="w-full">
              <div
                class="border border-blue p-8 mx-8 mt-8 text-center text-3xl"

              >
                <img src="../assets/images/slider11.jpg" width="100%">
              </div>
            </rl-carousel-slide>
            <rl-carousel-slide class="w-full">
              <div
                class="border border-red p-8 mx-8 mt-8 text-center text-3xl"

              >
                <img src="../assets/images/slider12.jpg" width="100%">
              </div>
            </rl-carousel-slide>
            <rl-carousel-slide class="w-full">
              <div
                class="border border-green p-8 mx-8 mt-8 text-center text-3xl"

              >
                <img src="../assets/images/slider13.jpg" width="100%">
              </div>
            </rl-carousel-slide>
          </SlidesComponent>
        </div>-->

    <!--  Content Start  -->
    <div class="custom-row-element-wrap mt-5 mb-5">
      <!--   What we do section   -->
      <div class="text-center mb-5">
        <div class="custom-widget mt-2 ">
          <h2 class=" ">What We Do</h2>
        </div>
        <div class="custom-row justify-content-center">
          <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
          <span class="ml-1 mr-1" style="color: #ea216c;font-size: 24px">
<!--            <i class="far fa-user"></i>-->
            <b-icon icon="person"></b-icon>
          </span>
          <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
        </div>

      </div>
      <div class="pt-4"></div>
      <div class="row">

        <div class="col-lg-6">

            <div class="post-container">
              <div class="custom-widget mt-2 mb-4 border-bottom ">
                <h2 class="widget-title text-uppercase">Your Complete Financial Solutions</h2>
              </div>
              <div>
                <p class="custom-service-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </div>
            </div>
        </div>

        <div class="col-lg-6">
          <div class="row">
            <div class="col-lg-6">
              <div>
                <div class="custom-widget mt-2 mb-2">
                  <h3 class="widget-title">Financial Service</h3>
                </div>
                <br>

                <p class="custom-service-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </div>
              <div>
                <div class="custom-widget mt-2 mb-2">
                  <h3 class="widget-title">Business Analysis</h3>
                </div>
                <br>

                <p class="custom-service-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div>
                <div class="custom-widget mt-2 mb-2">
                  <h3 class="widget-title">Best Consultancy</h3>
                </div>
                <br>

                <p class="custom-service-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </div>
              <div>
                <div class="custom-widget mt-2 mb-2">
                  <h3 class="widget-title">24/7 Support</h3>
                </div>
                <br>

                <p class="custom-service-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>

    <!-- Content-->
    <div class="custom-row-element-wrap mt-5 mb-5">
      <!--   banner   -->
      <div class="custom-whatwedo-banner-section">
        <div class="custom-banner-background-overlay"></div>
        <div class="custom-section">
          <div class="custom-row-wrap">
            <div class="custom-row-element ">
              <div class="custom-row-element-wrap ">
                <!--              <div class="row custom-row-section ">
                                <div class="col-lg-12 custom-column-banner-heading custom-carousel-left-z-index text-center ">

                                  <div class="custom-banner-heading text-uppercase">
                                    <span>Creativity</span>
                                    <span class="ml-4 mr-4" style="color: #ea216c">
                                      <b-icon width="1.5rem" icon="circle-fill"></b-icon>
                                    </span>
                                    <span>Passion</span>
                                    <span class="ml-4 mr-4" style="color: #ea216c">
                                      <b-icon width="1.5rem" icon="circle-fill"></b-icon>
                                    </span>
                                    <span>Desire</span>
                                  </div>

                                  &lt;!&ndash;                        <div class="row mt-4 w3-animate-bottom">
                                                            <div class="col-lg-6">

                                                              <div class="custom-form-submit-btn">

                                                                <router-link :to="{name: 'SignUp'}"
                                                                             class="btn custom-btn-search custom-employee-btn my-2 ">
                                                              <span class="custom-btn-more-text font-weight-bold">
                                                                <i class="fas fa-plus-circle"></i>
                                                                Register As Employee
                                                              </span>
                                                                </router-link>
                                                              </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                              <div class="custom-form-submit-btn">

                                                                <router-link :to="{name: 'SignUp'}"
                                                                             class="btn custom-btn-search custom-employer-btn my-2">
                                                                  <span class="custom-btn-more-text font-weight-bold">
                                                                    <i class="fas fa-plus-circle"></i>
                                                                    Register As Employer
                                                                  </span>
                                                                </router-link>
                                                              </div>
                                                            </div>
                                                            &lt;!&ndash;                          <div class="col-lg-2"></div>&ndash;&gt;
                                                          </div>&ndash;&gt;
                                </div>

                              </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
<!--      <div class="text-center mb-5">
        <div class="custom-widget mt-2 ">
          <h2 class=" ">Get In Touch</h2>
        </div>
        <div class="custom-row justify-content-center">
          <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
          <span class="ml-1 mr-1" style="color: #ea216c;font-size: 24px">
&lt;!&ndash;            <i class="far fa-user"></i>&ndash;&gt;
            <b-icon icon="bag-plus"></b-icon>
          </span>
          <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
        </div>

      </div>-->
      <div class="row align-items-center">
        <div class="col-lg-6">
         <div>
           <img src="../assets/images/plan-graph.jpg" width="100%">
         </div>
        </div>
        <div class="col-lg-6">
          <div class="post-container">
            <div class=" mt-2 mb-4 border-bottom ">
              <h5 class="text-uppercase custom-text-color">We Provide Best</h5>
              <h2 class="widget-title text-uppercase">Financial Plan For You</h2>
            </div>
            <div>
              <p class="custom-service-text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="text-center mb-5">
        <div class="custom-widget mt-2 ">
          <h2 class=" ">Why Choose Us</h2>
        </div>
        <div class="custom-row justify-content-center">
          <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
          <span class="ml-1 mr-1" style="color: #ea216c;font-size: 24px">
<!--            <i class="far fa-user"></i>-->
            <b-icon icon="person"></b-icon>
          </span>
          <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
        </div>

      </div>
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="row">
            <div class="col-lg-6">
              <div  class="mt-2 mb-2 custom-row">
                <span class="mr-3 mb-auto" >
                  <div class="text-center">
                    <div class="custom-card-icon custom-whatwedo-icon">
                      <i class="custom-contact-icon-style custom-whatwedo-icon-style custom-service-icon far fa-paper-plane"></i>
<!--                      <b-icon class="custom-contact-icon-style custom-whatwedo-icon-style custom-service-icon"  icon="geo-alt-fill"></b-icon>-->
                    </div>

                  </div>
                  <!--                      <img :src="url+job.employeeImage" alt=""
                  class="w3-left w3-circle w3-margin-right"
                  style="width: 60px; height: 60px">-->
                </span>

                <div class="flex-grow-1 mb-auto">
                  <div class="custom-widget ">
                    <span class="widget-title">Fast Loan Approval</span>
                  </div>

                  <p class="custom-service-text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p>
                </div>

              </div>
              <div  class="mt-2 mb-2 custom-row">
                <span class="mr-3 mb-auto" >
                  <div class="text-center">
                    <div class="custom-card-icon custom-whatwedo-icon">
<!--                       <i class="custom-contact-icon-style custom-whatwedo-icon-style custom-service-icon far fa-paper-plane"></i>-->
                      <b-icon class="custom-contact-icon-style custom-whatwedo-icon-style custom-service-icon m-0"  icon="files"></b-icon>
                    </div>

                  </div>
                  <!--                      <img :src="url+job.employeeImage" alt=""
                  class="w3-left w3-circle w3-margin-right"
                  style="width: 60px; height: 60px">-->
                </span>

                <div class="flex-grow-1 mb-auto">
                  <div class="custom-widget ">
                    <span class="widget-title">Fast Documentation</span>
                  </div>

                  <p class="custom-service-text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p>
                </div>

              </div>
            </div>
            <div class="col-lg-6">
              <div  class="mt-2 mb-2 custom-row">
                <span class="mr-3 mb-auto" >
                  <div class="text-center">
                    <div class="custom-card-icon custom-whatwedo-icon">
<!--                       <i class="custom-contact-icon-style custom-whatwedo-icon-style custom-service-icon far fa-paper-plane"></i>-->
                      <users-icon class="custom-class"></users-icon>
<!--                      <b-icon class="custom-contact-icon-style custom-whatwedo-icon-style custom-service-icon"  icon="geo-alt-fill"></b-icon>-->
                    </div>

                  </div>
                  <!--                      <img :src="url+job.employeeImage" alt=""
                  class="w3-left w3-circle w3-margin-right"
                  style="width: 60px; height: 60px">-->
                </span>

                <div class="flex-grow-1 mb-auto">
                  <div class="custom-widget ">
                    <span class="widget-title">Dedicated Team</span>
                  </div>

                  <p class="custom-service-text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p>
                </div>

              </div>
              <div  class="mt-2 mb-2 custom-row">
                <span class="mr-3 mb-auto" >
                  <div class="text-center">
                    <div class="custom-card-icon custom-whatwedo-icon">
<!--                       <i class="custom-contact-icon-style custom-whatwedo-icon-style custom-service-icon far fa-paper-plane"></i>-->
                      <b-icon class="custom-contact-icon-style custom-whatwedo-icon-style custom-service-icon m-0"  icon="arrow-clockwise"></b-icon>
                    </div>

                  </div>
                  <!--                      <img :src="url+job.employeeImage" alt=""
                  class="w3-left w3-circle w3-margin-right"
                  style="width: 60px; height: 60px">-->
                </span>

                <div class="flex-grow-1 mb-auto">
                  <div class="custom-widget ">
                    <span class="widget-title">Refinancing</span>
                  </div>

                  <p class="custom-service-text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div>
            <img src="../assets/images/whychooseus.jpg" width="100%">
          </div>
        </div>
      </div>

    </div>

    <!--   Spacer   -->
    <div class="pt-5"></div>
    <!--  Contact Us    -->
    <div>
      <div class="text-center clear-bottom">
        <router-link to="/contact-us" class="btn link-theme text-decoration-none">Contact Us</router-link>
      </div>
    </div>
    <!--   Spacer   -->
    <div class="pt-5"></div>
  </div>
</template>

<script>

// optional style for arrows & dots

import axios from 'axios'
import { UsersIcon } from 'vue-feather-icons'
// import { RlCarouselSlide } from 'vue-renderless-carousel'
export default {
  name: 'WhatWeDo',
  components: {
    // SlidesComponent,
    // RlCarouselSlide
    UsersIcon
  },
  data () {
    return {

      url: axios.defaults.baseURL,
      currentPage: 1,
      total: 1,

      list: null,
      PageOfItems: [],
      info: [],
      option: 0,

      loading: true,
      errored: false,

      componentKey: 0
    }
  },
  // carousel_latest_jobs
  methods: {

    // Rerender Component
    rerender () {
      this.componentKey += 1
    }
  },

  mounted () {

  }
}
</script>

<template>
  <div id="main-container" :key="componentKey">


    <!--  Content Start  -->
    <div class="custom-row-element-wrap mt-5 mb-5">
      <!--   What we do section   -->

      <div id="whatWeDo_id">
        <div class="text-center mb-5">
          <div class="custom-widget mt-2 ">
            <h2 class="custom-text-color">{{ $t('Home.WhatWeDo') }}</h2>
          </div>
          <div class="custom-row justify-content-center">
            <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
            <span class="ml-1 mr-1" style="color: #ea216c;font-size: 24px">
<!--            <i class="far fa-user"></i>-->
            <b-icon icon="person"></b-icon>
          </span>
            <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
          </div>
          <div v-if="info != null && info.length != 0" class="custom-description">
            <h3 v-if="urdudata == false" >{{ info[0].whatWeDo }}</h3>
            <h3 v-else>{{ info[0].urduWhatWeDo }}</h3>
          </div>
          <div v-else>
            <b-alert variant="light" show>Loading...</b-alert>
          </div>
        </div>
        <div class="pt-4"></div>
        <div class="row no-gutters">

          <div class="col-lg-4">

            <router-link :to="{ name: 'PartnersAndFunding',
                              params: { type: 1, searchString:0 }
                            }" class="text-decoration-none">
              <div class="text-center">
                <div class="custom-card-icon">
                  <b-icon icon="briefcase"></b-icon>
                </div>
                <div class="custom-heading">
                  <h2 class="widget-title">{{ $t('Main.BusinessServices') }}</h2>
                </div>
              </div>
            </router-link>

          </div>

          <div class="col-lg-4">
            <router-link :to="{ name: 'PartnersAndFunding',
                              params: { type: 2, searchString:0 }
                            }" class="text-decoration-none">
              <div class="text-center">
                <div class="custom-card-icon">
                  <b-icon icon="cash-stack"></b-icon>
                </div>
                <div>
                  <h2 class="widget-title">{{ $t('Main.Investment') }}</h2>
                </div>
              </div>
            </router-link>

          </div>
          <div class="col-lg-4">
            <a href="https://www.harshehar.com/#/social-register" target="_blank" class="text-decoration-none">
              <div class="text-center">
                <div class="custom-card-icon">
                  <b-icon icon="journal-text"></b-icon>
                </div>
                <div>
                  <h2 class="widget-title">{{ $t('Main.SocialRegister') }}</h2>
                </div>
              </div>
            </a>

          </div>
        </div>
        <!--   Spacer   -->
        <div class="pt-5"></div>
        <!--   Spacer   -->
        <div class="pt-5"></div>
      </div>

      <!--   Why ventureher   -->
      <div id="ourWhyVentureHer_id">
        <div  class="text-center mb-5">
          <div class="custom-widget mt-2 ">
            <h2 class=" custom-text-color ">{{ $t('Home.WhyVentureher') }}</h2>
          </div>
          <div class="custom-row justify-content-center">
            <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
            <span class="ml-1 mr-1" style="color: #ea216c;font-size: 24px">
<!--            <i class="far fa-user"></i>-->
            <b-icon icon="person"></b-icon>
          </span>
            <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
          </div>

        </div>
        <div v-if="urdudata == false" class="row align-items-center no-gutters  w-100">
          <div class="col-lg-7  w-100">
            <div class="row no-gutters">
              <div v-for="data in whyVentureHerList" :key="data.id" class="col-lg-6">
                <router-link :to="{ name: 'Detail',
                              params: { id: data.id }
                            }" class="text-decoration-none custom-ellipsis-icon">
                  <div  class="m-2 custom-row align-items-start">
                <span  class="mr-3 mb-auto" >
<!--                  <div class="text-center">
                    <div class="custom-card-icon custom-whatwedo-icon">
                      <i class="custom-contact-icon-style custom-whatwedo-icon-style custom-service-icon far fa-paper-plane"></i>
                      &lt;!&ndash;                      <b-icon class="custom-contact-icon-style custom-whatwedo-icon-style custom-service-icon"  icon="geo-alt-fill"></b-icon>&ndash;&gt;
                    </div>

                  </div>-->
                  <img :src="url+data.image" alt=""
                       class="w3-left w3-circle "
                       style="width: 60px; height: 60px; object-fit: contain;background-color: #fff">
                </span>
                    <!--                  <span v-else class="mr-3 mb-auto" >
                    &lt;!&ndash;                  <div class="text-center">
                                        <div class="custom-card-icon custom-whatwedo-icon">
                                          <i class="custom-contact-icon-style custom-whatwedo-icon-style custom-service-icon far fa-paper-plane"></i>
                                          &lt;!&ndash;                      <b-icon class="custom-contact-icon-style custom-whatwedo-icon-style custom-service-icon"  icon="geo-alt-fill"></b-icon>&ndash;&gt;
                                        </div>

                                      </div>&ndash;&gt;
                                      <img src="../assets/images/avatar-2.jpg" alt=""
                                           class="w3-left w3-circle "
                                           style="width: 60px; height: 60px">
                                    </span>-->
                    <div class="flex-grow-1 mb-auto">
                      <div>
                        <div  class="custom-widget ">
                          <span class="widget-title mt-0">{{ data.title }}</span>
                        </div>
                        <p class="custom-service-text">
                          {{ data.description }}
                        </p>
                      </div>

                    </div>

                  </div>
                </router-link>

              </div>
          
            </div>
          </div>
          <div class="col-lg-5 w-100">
            <div v-if="info != null && info.length != 0" >
              <img :src="url+info[0].image" class="w-100 custom-image-height" >
            </div>
          </div>
        </div>
        <div v-else class="row align-items-center no-gutters  w-100">
          <div class="col-lg-7  w-100">
            <div class="row no-gutters">
              <div v-for="data in whyVentureHerList" :key="data.id" class="col-lg-6">
                <router-link :to="{ name: 'Detail',
                              params: { id: data.id }
                            }" class="text-decoration-none custom-ellipsis-icon">
                  <div  class="m-2 custom-row align-items-start">
                <span  class="mr-3 mb-auto" >
<!--                  <div class="text-center">
                    <div class="custom-card-icon custom-whatwedo-icon">
                      <i class="custom-contact-icon-style custom-whatwedo-icon-style custom-service-icon far fa-paper-plane"></i>
                      &lt;!&ndash;                      <b-icon class="custom-contact-icon-style custom-whatwedo-icon-style custom-service-icon"  icon="geo-alt-fill"></b-icon>&ndash;&gt;
                    </div>

                  </div>-->
                  <img :src="url+data.image" alt=""
                       class="w3-left w3-circle "
                       style="width: 60px; height: 60px; object-fit: contain;background-color: #fff">
                </span>
                    <!--                  <span v-else class="mr-3 mb-auto" >
                    &lt;!&ndash;                  <div class="text-center">
                                        <div class="custom-card-icon custom-whatwedo-icon">
                                          <i class="custom-contact-icon-style custom-whatwedo-icon-style custom-service-icon far fa-paper-plane"></i>
                                          &lt;!&ndash;                      <b-icon class="custom-contact-icon-style custom-whatwedo-icon-style custom-service-icon"  icon="geo-alt-fill"></b-icon>&ndash;&gt;
                                        </div>

                                      </div>&ndash;&gt;
                                      <img src="../assets/images/avatar-2.jpg" alt=""
                                           class="w3-left w3-circle "
                                           style="width: 60px; height: 60px">
                                    </span>-->
                    <div class="flex-grow-1 mb-auto">
                      <div>
                        <div  class="custom-widget ">
                          <span class="widget-title mt-0">{{ data.urduTitle }}</span>
                        </div>
                        <p class="custom-service-text">
                          {{ data.urduDescription }}
                        </p>
                      </div>

                    </div>

                  </div>
                </router-link>

              </div>
          
            </div>
          </div>
          <div class="col-lg-5 w-100">
            <div v-if="info != null && info.length != 0" >
              <img :src="url+info[0].image" class="w-100 custom-image-height" >
            </div>
          </div>
        </div>
        <!--   Spacer   -->
        <div class="pt-5"></div>
        <!--   Spacer   -->
        <div class="pt-5"></div>
      </div>

      <!--   our services section   -->
      <div id="ourServices_id">

        <div  class="text-center  mb-5">
          <div class="custom-widget mt-2 ">
            <h2 class="custom-text-color ">{{ $t('Home.OurServices') }}</h2>
          </div>
          <div class="custom-row justify-content-center">
            <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
            <span class="ml-1 mr-1" style="color: #ea216c;font-size: 24px">
<!--            <i class="far fa-user"></i>-->
            <b-icon icon="trophy"></b-icon>
          </span>
            <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
          </div>
          <div class="custom-service-text">
            <h4>
              {{ $t('Home.Wehavesubsidized') }}

            </h4>
          </div>
        </div>

        <div v-if="services != null">
          <div class="pt-4"></div>

          <div v-if="urdudata == false" class="row no-gutters">
            <div v-for="data in services" :key="data.id" class="col-lg-4">
              <div class="ml-2 mr-2 mt-2 mb-2">
                <router-link :to="{ name: 'ServiceDetail',
                              params: { id: data.id }
                            }" class="text-decoration-none custom-ellipsis-icon">
                  <div  class=" custom-row align-items-start">
            <span class="mr-5 mt-2 mb-2" >
<!--              <div class="text-center">
                <div class="custom-card-icon custom-service-icon">
                  <b-icon icon="building"></b-icon>
                </div>

              </div>-->

              <img :src="url+data.videoOrImage" alt=""
                   class="w3-left w3-circle w3-margin-right"
                   style="width: 80px; height: 80px;object-fit: contain;background-color: #fff">
            </span>

                    <div class="flex-grow-1">
                      <div class="custom-widget">
                        <h3 class="widget-title m-0 mb-1">{{ data.title }}</h3>
                      </div>

                      <p class="custom-service-text mb-0">
                        {{ data.description }}...
                      </p>

                    </div>

                  </div>
                  <div class="row no-gutters">
                    <div class="col-lg-9"></div>
                    <div class="col-lg-3">
                      <div class="custom-text-right">
                        Read More
                      </div>
                    </div>
                  </div>
                </router-link>

              </div>
            </div>
            <!--          <div class="col-lg-4">
                        <div  class="mt-2 mb-2 custom-row">
                        <span class="mr-5" >
                          <div class="text-center">
                            <div class="custom-card-icon custom-service-icon">
                              <b-icon icon="pencil"></b-icon>
                            </div>

                          </div>
                          &lt;!&ndash;                      <img :src="url+job.employeeImage" alt=""
                          class="w3-left w3-circle w3-margin-right"
                          style="width: 60px; height: 60px">&ndash;&gt;
                        </span>

                          <div class="flex-grow-1">
                            <div class="custom-widget mt-2 mb-2">
                              <h3 class="widget-title">Logo & Website Design</h3>
                            </div>
                            <br>

                            <p class="custom-service-text">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </p>
                          </div>

                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div  class="mt-2 mb-2 custom-row">
                        <span class="mr-5" >
                          <div class="text-center">
                            <div class="custom-card-icon custom-service-icon">
                              <b-icon icon="graph-up"></b-icon>
                            </div>

                          </div>
                          &lt;!&ndash;                      <img :src="url+job.employeeImage" alt=""
                          class="w3-left w3-circle w3-margin-right"
                          style="width: 60px; height: 60px">&ndash;&gt;
                        </span>

                          <div class="flex-grow-1">
                            <div class="custom-widget mt-2 mb-2">
                              <h3 class="widget-title">NTN/Tax Return</h3>
                            </div>
                            <br>

                            <p class="custom-service-text">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </p>
                          </div>

                        </div>
                      </div>-->

          </div>
          <div v-else class="row no-gutters">
            <div v-for="data in services" :key="data.id" class="col-lg-4">
              <div class="ml-2 mr-2 mt-2 mb-2">
                <router-link :to="{ name: 'ServiceDetail',
                              params: { id: data.id }
                            }" class="text-decoration-none custom-ellipsis-icon">
                  <div  class=" custom-row align-items-start">
            <span class="mr-5 mt-2 mb-2" >
<!--              <div class="text-center">
                <div class="custom-card-icon custom-service-icon">
                  <b-icon icon="building"></b-icon>
                </div>

              </div>-->

              <img :src="url+data.videoOrImage" alt=""
                   class="w3-left w3-circle w3-margin-right"
                   style="width: 80px; height: 80px;object-fit: contain;background-color: #fff">
            </span>

                    <div class="flex-grow-1">
                      <div class="custom-widget">
                        <h3 class="widget-title m-0 mb-1">{{ data.urduTitle }}</h3>
                      </div>

                      <p class="custom-service-text mb-0">
                        {{ data.urduDescription }}...
                      </p>

                    </div>

                  </div>
                  <div class="row no-gutters">
                    <div class="col-lg-9"></div>
                    <div class="col-lg-3">
                      <div class="custom-text-right">
                        Read More
                      </div>
                    </div>
                  </div>
                </router-link>

              </div>
            </div>
            <!--          <div class="col-lg-4">
                        <div  class="mt-2 mb-2 custom-row">
                        <span class="mr-5" >
                          <div class="text-center">
                            <div class="custom-card-icon custom-service-icon">
                              <b-icon icon="pencil"></b-icon>
                            </div>

                          </div>
                          &lt;!&ndash;                      <img :src="url+job.employeeImage" alt=""
                          class="w3-left w3-circle w3-margin-right"
                          style="width: 60px; height: 60px">&ndash;&gt;
                        </span>

                          <div class="flex-grow-1">
                            <div class="custom-widget mt-2 mb-2">
                              <h3 class="widget-title">Logo & Website Design</h3>
                            </div>
                            <br>

                            <p class="custom-service-text">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </p>
                          </div>

                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div  class="mt-2 mb-2 custom-row">
                        <span class="mr-5" >
                          <div class="text-center">
                            <div class="custom-card-icon custom-service-icon">
                              <b-icon icon="graph-up"></b-icon>
                            </div>

                          </div>
                          &lt;!&ndash;                      <img :src="url+job.employeeImage" alt=""
                          class="w3-left w3-circle w3-margin-right"
                          style="width: 60px; height: 60px">&ndash;&gt;
                        </span>

                          <div class="flex-grow-1">
                            <div class="custom-widget mt-2 mb-2">
                              <h3 class="widget-title">NTN/Tax Return</h3>
                            </div>
                            <br>

                            <p class="custom-service-text">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </p>
                          </div>

                        </div>
                      </div>-->

          </div>
          <!--   Spacer   -->
          <div class="pt-5"></div>
          <!--  Load more    -->
          <div>
            <div class="text-center clear-bottom">
              <router-link to="/all-services" class="btn link-theme text-decoration-none" href="#">
                <i aria-hidden="true" class="fa fa-plus-circle"> </i>   {{ $t('Home.ViewMore') }}</router-link
              >
            </div>
          </div>
        </div>
        <!--   Spacer   -->
        <div class="pt-5"></div>
        <!--   Spacer   -->
        <div class="pt-5"></div>
      </div>

      <!--   Our Packages   -->
      <div id="ourPackages_id">
        <div  class="text-center mb-5">
          <div class="custom-widget mt-2 ">
            <h2 class=" custom-text-color">{{ $t('Home.OurPackages') }}</h2>
          </div>
          <div class="custom-row justify-content-center">
            <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
            <span class="ml-1 mr-1" style="color: #ea216c;font-size: 24px">
<!--            <i class="far fa-user"></i>-->
            <b-icon icon="person"></b-icon>
          </span>
            <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
          </div>

        </div>

        <div v-if="packages != null">
          <div v-if="urdudata == false" class="row no-gutters ">
            <div v-for="data in packages" :key="data.id" class="col-lg-3 card custom-package-card">
              <router-link :to="{ name: 'PackageDetail',
                              params: { id: data.id }
                            }" class="text-decoration-none custom-ellipsis-icon">
                <div class=" text-center p-4">

                  <div class="card-header bg-transparent">
                    <div class=" mt-4 mb-2">
                      <!--              <span style="font-size: 2rem">Rs99</span>
                                    <span>/mon</span>-->
                      <b-icon style="font-size: 2rem;" width="3rem"  height="3rem" icon="cash-stack"></b-icon>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="">
                      <!--              <div class="custom-widget mt-2 mb-2">
                                      <h3 class="m-0 widget-title" style="color: #ea216c"></h3>
                                    </div>-->
                      <div class="custom-description">
                        <h3 class="mt-0 text-uppercase">{{ data.title }}</h3>
                      </div>

                      <p class="custom-service-text">
                        {{ data.description }}
                      </p>
                    </div>
                  </div>

                  <div class="card-footer bg-transparent">

                    <span class="text-uppercase ">{{ data.trial }}</span>
                  </div>
                  <!--          <div class="bar">
                              <div class="emptybar"></div>
                              <div class="filledbar"></div>
                            </div>
                            <div class="circle">
                              <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
                                <circle class="stroke" cx="60" cy="60" r="50"/>
                              </svg>
                            </div>-->
                </div>
              </router-link>

            </div>
            <!--          <div class="col-lg-3 card custom-package-card">
                        <div class="text-center p-4">
                          <div class="card-header bg-transparent">
                            <div class=" mt-4 mb-2">
                              <b-icon style="font-size: 2rem;color: #25D366" width="3rem"  height="3rem" icon="cash-stack"></b-icon>
                            </div>
                          </div>

                          <div class="card-body">
                            <div class="">
                              &lt;!&ndash;              <div class="custom-widget mt-2 mb-2">
                                              <h3 class="m-0 widget-title" style="color: #ea216c"></h3>
                                            </div>&ndash;&gt;
                              <div class="">
                                <h3 class="mt-0 text-uppercase">Option B</h3>
                              </div>

                              <p class="custom-service-text">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                              </p>
                            </div>
                          </div>

                          <div class="card-footer bg-transparent">

                            <a href="javascript:void(0)" class="post-title text-uppercase ">Try it for 14 Days</a>
                          </div>
                          &lt;!&ndash;          <div class="bar">
                                      <div class="emptybar"></div>
                                      <div class="filledbar"></div>
                                    </div>
                                    <div class="circle">
                                      <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
                                        <circle class="stroke" cx="60" cy="60" r="50"/>
                                      </svg>
                                    </div>&ndash;&gt;
                        </div>
                      </div>
                      <div class="col-lg-3 card custom-package-card">
                        <div class="text-center p-4">
                          <div class="card-header bg-transparent">
                            <div class=" mt-4 mb-2">
                              <b-icon style="font-size: 2rem;color: #35cbf3" width="3rem"  height="3rem" icon="cash-stack"></b-icon>
                            </div>
                          </div>

                          <div class="card-body">
                            <div class="">
                              &lt;!&ndash;              <div class="custom-widget mt-2 mb-2">
                                              <h3 class="m-0 widget-title" style="color: #ea216c"></h3>
                                            </div>&ndash;&gt;
                              <div class="">
                                <h3 class="mt-0 text-uppercase">Option C</h3>
                              </div>

                              <p class="custom-service-text">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                              </p>
                            </div>
                          </div>

                          <div class="card-footer bg-transparent">

                            <a href="javascript:void(0)" class="post-title text-uppercase ">Try it for 14 Days</a>
                          </div>
                          &lt;!&ndash;          <div class="bar">
                                      <div class="emptybar"></div>
                                      <div class="filledbar"></div>
                                    </div>
                                    <div class="circle">
                                      <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
                                        <circle class="stroke" cx="60" cy="60" r="50"/>
                                      </svg>
                                    </div>&ndash;&gt;
                        </div>
                      </div>
                      <div class="col-lg-3 card custom-package-card">
                        <div class="text-center p-4">
                          <div class="card-header bg-transparent">
                            <div class=" mt-4 mb-2">
                              <b-icon style="font-size: 2rem;color: #708fef" width="3rem"  height="3rem" icon="cash-stack"></b-icon>
                            </div>
                          </div>
                          <div class="card-body">
                            <div class="">
                              &lt;!&ndash;              <div class="custom-widget mt-2 mb-2">
                                              <h3 class="m-0 widget-title" style="color: #ea216c"></h3>
                                            </div>&ndash;&gt;
                              <div class="">
                                <h3 class="mt-0 text-uppercase">Option D</h3>
                              </div>

                              <p class="custom-service-text">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                              </p>
                            </div>
                          </div>

                          <div class="card-footer bg-transparent">

                            <a href="javascript:void(0)" class="post-title text-uppercase ">Try it for 14 Days</a>
                          </div>

                          &lt;!&ndash;          <div class="bar">
                                      <div class="emptybar"></div>
                                      <div class="filledbar"></div>
                                    </div>
                                    <div class="circle">
                                      <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
                                        <circle class="stroke" cx="60" cy="60" r="50"/>
                                      </svg>
                                    </div>&ndash;&gt;
                        </div>
                      </div>-->

          </div>
          <div v-else class="row no-gutters ">
            <div v-for="data in packages" :key="data.id" class="col-lg-3 card custom-package-card">
              <router-link :to="{ name: 'PackageDetail',
                              params: { id: data.id }
                            }" class="text-decoration-none custom-ellipsis-icon">
                <div class=" text-center p-4">

                  <div class="card-header bg-transparent">
                    <div class=" mt-4 mb-2">
                      <!--              <span style="font-size: 2rem">Rs99</span>
                                    <span>/mon</span>-->
                      <b-icon style="font-size: 2rem;" width="3rem"  height="3rem" icon="cash-stack"></b-icon>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="">
                      <!--              <div class="custom-widget mt-2 mb-2">
                                      <h3 class="m-0 widget-title" style="color: #ea216c"></h3>
                                    </div>-->
                      <div class="custom-description">
                        <h3 class="mt-0 text-uppercase">{{ data.urduTitle }}</h3>
                      </div>

                      <p class="custom-service-text">
                        {{ data.urduDescription }}
                      </p>
                    </div>
                  </div>

                  <div class="card-footer bg-transparent">

                    <span class="text-uppercase ">{{ data.trial }}</span>
                  </div>
           </div>
              </router-link>

            </div>
         
          </div>
          <!--   Spacer   -->
          <div class="pt-5"></div>
          <!--  Load more    -->
          <div>
            <div class="text-center clear-bottom">
              <router-link to="/all-packages" class="btn link-theme text-decoration-none" href="#">
                <i aria-hidden="true" class="fa fa-plus-circle"> </i>{{ $t('Home.ViewMore') }}</router-link
              >
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- Complete profile Modal-->
    <div class="">

    </div>
  </div>
</template>

<script>

// optional style for arrows & dots

import axios from 'axios'

// import { RlCarouselSlide } from 'vue-renderless-carousel'
export default {
  name: 'Home',
  components: {
    // SlidesComponent,
    // RlCarouselSlide

  },
  data () {
    return {
      url: axios.defaults.baseURL,
      currentPage: 1,
      total: 1,

      list: null,
      PageOfItems: [],

      option: 0,

      // Dynamic Content Start

      whatWeDoModel: {
        PageSize: 1,
        PageNumber: 1,
        Text: ''
      },
      whyVentureModel: {
        PageSize: 0,
        PageNumber: 1,
        Text: ''
      },
      servicesModel: {
        PageSize: 6,
        PageNumber: 1,
        Text: ''
      },
      packagesModel: {
        PageSize: 8,
        PageNumber: 1,
        Text: ''
      },

      info: [],
      whyVentureHerList: [],
      services: [],
      packages: [],

      loading: true,
      errored: false,

      componentKey: 0
    }
  },
  // carousel_latest_jobs
  methods: {
    getContent () {
      this.rerender()

      this.info = []
      axios.post('/api/Shehri/GetAll', this.whatWeDoModel)
        .then(res => {
          if (res.status != '200') {
            console.log('error.....')
            const error = new Error('Failed to fetch!')
            return error
          }

          let list = []
          list = res.data.data
          this.info = list.slice().reverse()

          console.log('what we do and why ventureher : ', this.info)
          // let currentDate = moment(String(new Date())).format('YYYY-MM-DD')

          /* for (let post in this.list){
            console.log("this.list[post]: ",this.list[post])
            if(this.list[post].postType === 1){
              this.featuredPosts.push(this.list[post]);
            }

          }

          console.log("Featured Jobs : ", this.featuredPosts);
          */
        }).catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => {
          if (this.errored) {
            console.log('Error : Sorry there is no response')
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          }
        })
    },
    onLocaleChange(newValue, oldValue) {
      // This function is called when $i18n.locale changes
      // Implement your logic here to handle the locale change
      console.log('Locale changed!');
      console.log('New locale:', newValue);
      console.log('Old locale:', oldValue);

      // Call your desired function here
      this.yourFunctionToHandleLocaleChange(newValue);
    },
    yourFunctionToHandleLocaleChange(newLocale) {
      // Implement your logic here to handle the locale change
    if(newLocale == 'ur'){
      debugger
      this.urdudata = true;
    }else{
      this.urdudata = false;
    }
     
    },
    getWhyVentureHer () {
      this.whyVentureHerList = []
      axios.post('/api/Shehri/GetVenturePartner', this.whyVentureModel)
        .then(res => {
          let list = []
          list = res.data.data

          console.log('list : ', list)

          for (const data in list) {
            if (list[data].type == 3) {
              this.whyVentureHerList.push(list[data])
            }
          }

          console.log('this.list : ', this.whyVentureHerList)
        }).catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => {
          if (this.errored) {
            console.log('Error : Sorry there is no response')
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          }
        })
    },
    getServices () {
      this.rerender()

      this.services = []
      axios.post('/api/Shehri/GetVentureServices', this.servicesModel)
        .then(res => {
          if (res.status != '200') {
            console.log('error.....')
            const error = new Error('Failed to fetch!')
            return error
          }

          this.services = res.data.data
          console.log('ventureher services : ', this.services)
          // let currentDate = moment(String(new Date())).format('YYYY-MM-DD')

          /* for (let post in this.list){
            console.log("this.list[post]: ",this.list[post])
            if(this.list[post].postType === 1){
              this.featuredPosts.push(this.list[post]);
            }

          }

          console.log("Featured Jobs : ", this.featuredPosts);
          */
          /* console.log("id : ", this.$route.params.id)
          if(this.$route.params.id != undefined && this.$route.params.id != "ourPackages_id"){
           this.scrollToServices(this.$route.params.id)
          } */
        }).catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => {
          if (this.errored) {
            console.log('Error : Sorry there is no response')
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          }
        })
    },
    getPackages () {
      this.rerender()

      this.packages = []
      axios.post('/api/Shehri/GetVenturePackages', this.packagesModel)
        .then(res => {
          if (res.status != '200') {
            console.log('error.....')
            const error = new Error('Failed to fetch!')
            return error
          }

          this.packages = res.data.data
          console.log('ventureher packages : ', this.packages)
          // let currentDate = moment(String(new Date())).format('YYYY-MM-DD')

          /* for (let post in this.list){
            console.log("this.list[post]: ",this.list[post])
            if(this.list[post].postType === 1){
              this.featuredPosts.push(this.list[post]);
            }

          }

          console.log("Featured Jobs : ", this.featuredPosts);
          */
          /* console.log("id : ", this.$route.params.id)
          if(this.$route.params.id != undefined && this.$route.params.id != "ourServices_id"){
            this.scrollToPackages(this.$route.params.id)
          } */
        }).catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => {
          if (this.errored) {
            console.log('Error : Sorry there is no response')
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          }
        })
    },

    // Rerender Component
    rerender () {
      this.componentKey += 1
    },
    scrollToServices (id) {
      const yOffset = 0
      /* if(id.match("accordion")){
        yOffset = -250;
      } */

      let element = null
      element = document.getElementById(id)
      console.log('element id : ', element)
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset

      console.log('element position : ', y)
      // $("#content_id").animate({ scrollBottom: y }, "fast");
      window.scrollTo({ top: y, behavior: 'smooth' })
    },
    scrollToPackages (id) {
      const yOffset = 0
      /* if(id.match("accordion")){
        yOffset = -250;
      } */

      let element = null
      element = document.getElementById(id)
      console.log('element id : ', element)
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset

      console.log('element position : ', y)
      // $("#content_id").animate({ scrollBottom: y }, "fast");
      window.scrollTo({ top: y, behavior: 'smooth' })
    }

  },

  computed: {

  },
  watch: {
    '$i18n.locale': {
      handler(newValue, oldValue) {
        // This function will be called whenever the $i18n.locale property changes
        // You can perform actions or call a function here when the locale changes
        this.onLocaleChange(newValue, oldValue);
      },
      deep: true, // If $i18n.locale is an object, watch for changes within it
    },
  },
  mounted () {
    this.getContent()
    this.getWhyVentureHer()
    this.getServices()
    this.getPackages()
    if(this.$i18n.locale == 'ur'){
      this.urdudata = true;
    }else{
      this.urdudata = false;
    }
    console.log('id : ', this.$route.params.id)
  }
}
</script>

import Vue from 'vue'
import 'jquery'
import 'popper.js'
import 'bootstrap'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
// import { RlCarousel, RlCarouselSlide } from 'vue-renderless-carousel'
import VueTelInput from 'vue-tel-input'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueTelInput)
// Vue.use(RlCarousel, RlCarouselSlide)

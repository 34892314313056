var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:_vm.componentKey},[_c('div',{staticClass:"custom-row-element-wrap mt-5 mb-5"},[_c('div',{staticClass:"post-container"},[_c('div',{staticClass:"custom-widget mt-2 mb-4 border-bottom"},[_c('h2',{staticClass:"widget-title text-uppercase",staticStyle:{"display":"flex"}},[_vm._v(_vm._s(_vm.$t('Main.ContactUs')))])]),_c('div',[(_vm.show)?_c('form',{staticClass:"needs-validation",class:[!_vm.isValidated  ? '':'', _vm.isValidated ? 'was-validated':''],attrs:{"autocomplete":"off","novalidate":""}},[_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-2 col-form-label",staticStyle:{"display":"flex"},attrs:{"for":"inputName"}},[_vm._v(" "+_vm._s(_vm.$t('ContactUS.Name'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-10"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userModel.name),expression:"userModel.name"}],staticClass:"form-control form-control-sm custom-input-gotopage",class:{
                              'is-invalid':
                                _vm.submitted && _vm.$v.userModel.name.$error,
                            },attrs:{"type":"text","id":"inputName","placeholder":_vm.$t('ContactUS.Name'),"pattern":"^[a-zA-Z\\s]*$"},domProps:{"value":(_vm.userModel.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.userModel, "name", $event.target.value)}}}),(_vm.submitted && !_vm.$v.userModel.name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Name is required ")]):_vm._e(),(!_vm.$v.userModel.name.minLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Name must contain at least 3 characters ")]):_vm._e(),(!_vm.$v.userModel.name.maxLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" The maximum length of 100 characters is reached ")]):_vm._e(),(!_vm.$v.userModel.name.alpha)?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v("Invalid Full Name, Only Characters are allowed Here")])]):_vm._e()])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-2 col-form-label",staticStyle:{"display":"flex"},attrs:{"for":"inputAddress"}},[_vm._v(" "+_vm._s(_vm.$t('ContactUS.Address'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-10"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userModel.address),expression:"userModel.address"}],staticClass:"form-control form-control-sm custom-input-gotopage",class:{
                              'is-invalid':
                                _vm.submitted && _vm.$v.userModel.address.$error,
                            },attrs:{"type":"text","id":"inputAddress","placeholder":_vm.$t('ContactUS.Address')},domProps:{"value":(_vm.userModel.address)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.userModel, "address", $event.target.value)}}}),(_vm.submitted && !_vm.$v.userModel.address.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Address is required ")]):_vm._e(),(!_vm.$v.userModel.address.minLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Address must contain at least 3 characters ")]):_vm._e(),(!_vm.$v.userModel.address.maxLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" The maximum length of 350 characters is reached ")]):_vm._e()])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-2 col-form-label",staticStyle:{"display":"flex"},attrs:{"for":"inputPhoneNo"}},[_vm._v(" "+_vm._s(_vm.$t('ContactUS.Phone'))+" # "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-10"},[_c('vue-tel-input',_vm._b({model:{value:(_vm.userModel.phone),callback:function ($$v) {_vm.$set(_vm.userModel, "phone", $$v)},expression:"userModel.phone"}},'vue-tel-input',_vm.vueTellProps,false)),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userModel.phone),expression:"userModel.phone"}],staticClass:"form-control form-control-sm custom-input-gotopage",class:{
                              'is-invalid':
                                _vm.submitted && _vm.$v.userModel.phone.$error,
                            },attrs:{"hidden":"","type":"text","id":"inputPhoneNo","placeholder":_vm.$t('ContactUS.Phone')},domProps:{"value":(_vm.userModel.phone)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.userModel, "phone", $event.target.value)}}}),(_vm.submitted && !_vm.$v.userModel.phone.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Phone # is required ")]):_vm._e()],1)]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-2 col-form-label",staticStyle:{"display":"flex"},attrs:{"for":"inputEmail"}},[_vm._v(" "+_vm._s(_vm.$t('ContactUS.Email'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-10"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userModel.email),expression:"userModel.email"}],staticClass:"form-control form-control-sm custom-input-gotopage",class:{
                              'is-invalid':
                                _vm.submitted && _vm.$v.userModel.email.$error,
                            },attrs:{"type":"email","id":"inputEmail","placeholder":_vm.$t('ContactUS.Email')},domProps:{"value":(_vm.userModel.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.userModel, "email", $event.target.value)}}}),(_vm.submitted && !_vm.$v.userModel.email.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Email is required ")]):_vm._e(),(!_vm.$v.userModel.email.email)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Accepts valid email addresses ")]):_vm._e()])]),_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"row"},[_c('legend',{staticClass:"col-form-label col-sm-2 pt-0",staticStyle:{"display":"flex"}},[_vm._v(" "+_vm._s(_vm.$t('ContactUS.NTNObtained'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-10"},[_c('b-form-group',{staticClass:"border-0",class:{
                              'is-invalid':
                                _vm.submitted && _vm.$v.userModel.ntn.$error,
                            }},[_c('b-form-radio-group',{attrs:{"options":_vm.ntnObtainedOptions,"name":"radio-inline1"},model:{value:(_vm.userModel.ntn),callback:function ($$v) {_vm.$set(_vm.userModel, "ntn", $$v)},expression:"userModel.ntn"}})],1),(_vm.submitted && !_vm.$v.userModel.ntn.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" NTN is required ")]):_vm._e()],1)])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-2 col-form-label",staticStyle:{"display":"flex"},attrs:{"for":"inputCompanyName"}},[_vm._v(" "+_vm._s(_vm.$t('ContactUS.CompanyName'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-10"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userModel.companyName),expression:"userModel.companyName"}],staticClass:"form-control form-control-sm custom-input-gotopage",class:{
                              'is-invalid':
                                _vm.submitted && _vm.$v.userModel.companyName.$error,
                            },attrs:{"type":"text","id":"inputCompanyName","placeholder":_vm.$t('ContactUS.CompanyName'),"pattern":"^[a-zA-Z\\s]*$"},domProps:{"value":(_vm.userModel.companyName)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.userModel, "companyName", $event.target.value)}}}),(_vm.submitted && !_vm.$v.userModel.companyName.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Company Name is required ")]):_vm._e(),(!_vm.$v.userModel.companyName.minLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Company Name must contain at least 3 characters ")]):_vm._e(),(!_vm.$v.userModel.companyName.maxLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" The maximum length of 100 characters is reached ")]):_vm._e(),(!_vm.$v.userModel.companyName.alpha)?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v("Invalid Company Name, Only Characters are allowed Here")])]):_vm._e()])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-2 col-form-label",staticStyle:{"display":"flex"},attrs:{"for":"inputWebsite"}},[_vm._v(_vm._s(_vm.$t('ContactUS.Website')))]),_c('div',{staticClass:"col-sm-10"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userModel.webSite),expression:"userModel.webSite"}],staticClass:"form-control form-control-sm custom-input-gotopage",class:{
                              'is-invalid':
                                _vm.submitted && _vm.$v.userModel.webSite.$error,
                            },attrs:{"type":"url","id":"inputWebsite","placeholder":_vm.$t('ContactUS.Website')},domProps:{"value":(_vm.userModel.webSite)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.userModel, "webSite", $event.target.value)}}}),(_vm.submitted && !_vm.$v.userModel.webSite.url)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Accepts only valid URLs. ")]):_vm._e()])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-2 col-form-label",staticStyle:{"display":"flex"},attrs:{"for":"inputMonthlyTurnover"}},[_vm._v(" "+_vm._s(_vm.$t('ContactUS.MonthlyTurnover'))+" ")]),_c('div',{staticClass:"col-sm-10"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userModel.monthlyTrunover),expression:"userModel.monthlyTrunover"}],staticClass:"form-control form-control-sm custom-input-gotopage",attrs:{"type":"number","id":"inputMonthlyTurnover","placeholder":_vm.$t('ContactUS.MonthlyTurnover')},domProps:{"value":(_vm.userModel.monthlyTrunover)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.userModel, "monthlyTrunover", $event.target.value)}}})])]),_c('div',{staticClass:"form-group row"},[_vm._m(0),_c('div',{staticClass:"col-sm-10"},[_c('multiselect',{staticClass:"custom-multiselect",class:{
                              'is-invalid':
                                _vm.submitted && _vm.$v.selectedServices.$error,
                            isServiceCustomMultiselect: _vm.isServiceCustomMultiselect },attrs:{"max-height":150,"tag-placeholder":"Add this as new tag","placeholder":"Search or add Service","options":_vm.services,"multiple":true,"taggable":true},on:{"input":function($event){return _vm.onSelectedServices(_vm.selectedServices)},"tag":_vm.addServiceTag},model:{value:(_vm.selectedServices),callback:function ($$v) {_vm.selectedServices=$$v},expression:"selectedServices"}}),(_vm.submitted && !_vm.$v.selectedServices.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please select the Services Required ")]):_vm._e()],1)]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-2 col-form-label",staticStyle:{"display":"flex"}},[_vm._v(" "+_vm._s(_vm.$t('ContactUS.PackagesRequired'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-10"},[_c('multiselect',{staticClass:"custom-multiselect",class:{
                              'is-invalid':
                                _vm.submitted && _vm.$v.selectedPackages.$error,
                            isPackageCustomMultiselect: _vm.isPackageCustomMultiselect },attrs:{"max-height":150,"tag-placeholder":"Add this as new tag","placeholder":_vm.$t('ContactUS.PackagesRequired'),"options":_vm.packages,"multiple":true,"taggable":true},on:{"input":function($event){return _vm.onSelectedPackages(_vm.selectedPackages)},"tag":_vm.addPackageTag},model:{value:(_vm.selectedPackages),callback:function ($$v) {_vm.selectedPackages=$$v},expression:"selectedPackages"}}),(_vm.submitted && !_vm.$v.selectedPackages.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please select the Packages Required ")]):_vm._e()],1)]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-2 col-form-label",staticStyle:{"display":"flex"},attrs:{"for":"inputDetailedDescription"}},[_vm._v(" "+_vm._s(_vm.$t('ContactUS.DetailedDescription')))]),_c('div',{staticClass:"col-sm-10"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.userModel.detailDescription),expression:"userModel.detailDescription"}],staticClass:"form-control form-control-sm custom-input-gotopage",class:{
                              'is-invalid':
                                _vm.submitted && _vm.$v.userModel.detailDescription.$error,
                            },attrs:{"type":"text","rows":"3","id":"inputDetailedDescription","placeholder":_vm.$t('ContactUS.DetailedDescription')},domProps:{"value":(_vm.userModel.detailDescription)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.userModel, "detailDescription", $event.target.value)}}})])]),_c('div',{staticClass:"modal-footer"},[(_vm.showResetButton)?_c('div',[_c('b-button',{staticClass:"form-control form-control-sm custom-form-btn custom-btn-gotopage",attrs:{"id":"showResetButton","size":"sm","variant":"outline-dark"},on:{"click":_vm.onReset}},[_vm._v(_vm._s(_vm.$t('Main.Reset'))+" ")])],1):_vm._e(),_c('div',[(_vm.showSaveButton)?_c('b-button',{staticClass:"form-control form-control-sm custom-form-btn custom-btn-gotopage",attrs:{"size":"sm","variant":"outline-dark"},on:{"click":function($event){return _vm.handleSubmit()}}},[_vm._v(_vm._s(_vm.$t('Main.Submit'))+" ")]):_vm._e()],1)])]):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"col-sm-2 col-form-label"},[_vm._v(" Services Required "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
}]

export { render, staticRenderFns }
<template>
  <div id="app" class="h-100">

    <Topbar class="mobileTopBar sticky-top" />
    <Sidebar  />
    <div id="topbar_id">
      <Topbar class="webTopBar" />

      <BannerHeader />

    </div>
    <MainMenu />

    <Sidebar />
    <Content />
    <Footer />

  </div>

</template>

<script>
import Content from '@/components/Content.vue'
import Sidebar from '@/components/Sidebar.vue'
import Topbar from './components/Topbar'
import BannerHeader from './components/BannerHeader'
import MainMenu from './components/MainMenu'
import Footer from '@/components/Footer'
export default {
  name: 'App',
  components: {
    Content,
    Topbar,
    BannerHeader,
    MainMenu,
    Sidebar,
    Footer
  }
}
</script>

<style>
</style>

import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // model:{},
    records: [],
    adds: []
  /*  businessRecord:[],
    fundingRecord:[], */
    // totalCount:0
  },
  mutations: {
    /*    setModel(state, model){
      state.model ={};
      state.model = model;
    }, */
    setRecords (state, records) {
      state.records = []
      state.records = records.slice().reverse()
    },
    setAdds (state, adds) {
      state.adds = adds
    }
    /*    setBusinessRecord(state, records){
      state.businessRecord =[];
      state.businessRecord = records.slice().reverse();
    },
    setFundingRecord(state, records){
      state.fundingRecord =[];
      state.fundingRecord = records.slice().reverse();
    }, */
    /* setTotalCount(state, totalCount){
      state.totalCount = totalCount;
    } */
  },
  actions: {
    fetchPartnersAndFunding (context, model) {
      // let fundingRecord = []
      // let totalCount = 0
      axios.post('/api/Shehri/GetVenturePartner', model.filter)
        .then(res => {
          const records = []
          let list = []
          // let model = model;

          /* totalCount = res.data.totalCount

          context.commit("setTotalCount", totalCount); */

          list = res.data.data

          console.log('list : ', list)

          if (model.articleType.type != 0) {
            for (const data in list) {
              // console.log("list[data]: ",list[data])
              if (list[data].type == model.articleType.type) {
                records.push(list[data])
              }
            }
            console.log('records : ', records)
            if (records != null || records != []) {
              console.log('setRecords : ', records)
              context.commit('setRecords', records)
            }
          } else {
            context.commit('setRecords', list)
          }
          // context.commit("setModel", model);
          // context.commit("setRecords",  list)
        }).catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => {
          if (this.errored) {
            console.log('Error : Sorry there is no response')
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          }
        })
    },
    fetchAdds (context) {
      let adds = []

      const addsModel = {
        pageSize: 0,
        pageNumber: 1,
        totalRecord: 0,
        text: ''
      }

      axios
        .post('/api/Adds/GetAdd', addsModel)
        .then(res => {
          // code that we will 'try' to run

          const jobList = res.data.data
          adds = jobList
          /* let currentDate = moment(String(new Date())).format('YYYY-MM-DD')
           for(let job in jobList){
               if(jobList[job].status == "Approved" && moment(String(jobList[job].jobExpiryDate)).format('YYYY-MM-DD') >= currentDate){
                   adds.push(jobList[job]);
               }
           } */

          console.log('Add Response portion')

          console.log('response : ', adds)
          context.commit('setAdds', adds)
          /* this.getCategories(this.list);
           this.getJobTypes(this.list);
           this.getExperiences(this.list);
           this.getFilteredCities(this.list); */
          // this.getDegreeLevel(this.list);

          /* if(adds.length == 0){
              this.$bvToast.toast("No Record Found ", {
                  toaster: "b-toaster-top-center",
                  variant: "warning",

                  solid: true
              });
          } */
        })
        .catch(error => {
          console.log('Errorrrrrr : ', error)
          this.errored = true
        })
        .finally(() => {
          if (this.errored) {
            console.log('Error : Sorry there is no response')
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          } else {

            /* this.$bvToast.toast("Updated Successfully ", {
              toaster: "b-toaster-top-center",
              variant: "success",

              solid: true
            }); */

          }
        })
    }
    /* showMore(context, visibleRecord){
      if(visibleRecord != 0){
        console.log("visibleRecord : ", visibleRecord);
        context.commit("setVisibleRecord",  visibleRecord)
      }
    } */
  },
  getters: {
    availableRecords (state, getters) {
      return state.records
      /* if(state.model.articleType.type != undefined && state.model.articleType.type != 0){
        return state.records.filter(record => record.type == state.model.articleType.type)
      }
      else{
        return state.records
      } */
    },

    //    available adds
    availableTopBarAdds (state, getters) {
      const topbarAdd = []

      for (const item of state.adds) {
        if (item.title == 'VentureHer/Topbar') {
          topbarAdd.push({
            id: item.addsId,
            title: item.title,
            image: item.addImage,
            url: item.bannerUrl
          })
        }
      }

      return topbarAdd.filter(item => item.id > 0)
    }

    /* availableFundingRecords(state, getters){

      return state.records.filter(record => record.type == 2)

    }, */
    /* totalCount(state, getters){
      return state.totalCount
    } */
  },
  modules: {
  }
})

<template>
  <div id="main-container" :key="componentKey">

    <!-- Banner Section For Web Start -->
    <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <section class="webSection  custom-landing-banner-section1">
            <div class="custom-banner-background-overlay"></div>
            <div class="custom-section">
              <div class="custom-row-wrap">
                <div class="custom-row-element ">
                  <div class="custom-row-element-wrap ">
                    <div class="row custom-row-section ">
                      <div class="col-lg-12 custom-column-banner-heading custom-carousel-left-z-index text-center ">
                        <div>
                          <h4><b-badge  class=" pt-1 pb-1 pl-3 pr-3 font-weight-normal" style="background-color: limegreen">Culture</b-badge></h4>
                        </div>
                        <div class="custom-banner-heading">
                          <span>Badshahi Mosque is an important remarkable of Mughal Architecture</span>
                        </div>

                        <!--                        <div class="row mt-4 w3-animate-bottom">
                                                  <div class="col-lg-6">

                                                    <div class="custom-form-submit-btn">

                                                      <router-link :to="{name: 'SignUp'}"
                                                                   class="btn custom-btn-search custom-employee-btn my-2 ">
                                                    <span class="custom-btn-more-text font-weight-bold">
                                                      <i class="fas fa-plus-circle"></i>
                                                      Register As Employee
                                                    </span>
                                                      </router-link>
                                                    </div>
                                                  </div>
                                                  <div class="col-lg-6">
                                                    <div class="custom-form-submit-btn">

                                                      <router-link :to="{name: 'SignUp'}"
                                                                   class="btn custom-btn-search custom-employer-btn my-2">
                                                        <span class="custom-btn-more-text font-weight-bold">
                                                          <i class="fas fa-plus-circle"></i>
                                                          Register As Employer
                                                        </span>
                                                      </router-link>
                                                    </div>
                                                  </div>
                                                  &lt;!&ndash;                          <div class="col-lg-2"></div>&ndash;&gt;
                                                </div>-->
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="carousel-item">
          <section class="webSection custom-landing-banner-section2">
            <div class="custom-banner-background-overlay"></div>
            <div class="custom-section">
              <div class="custom-row-wrap">
                <div class="custom-row-element">
                  <div class="custom-row-element-wrap">
                    <div class="row custom-row-section ">
                      <div class="col-lg-12 custom-column-banner-heading custom-carousel-left-z-index ">
                        <div class="custom-banner-heading">
                          <span>Best Place To <span class="custom-text-color">Grow</span></span><br>
                          <span>Your Career</span>
                        </div>
                        <div class="custom-banner-p">
                          <p>Find Jobs, Employment and Career Opportunities</p>
                        </div>
                        <!--                        <div class="row mt-4 w3-animate-bottom">
                                                  <div class="col-lg-6">
                                                    <div class="custom-form-submit-btn">

                                                      <router-link :to="{name: 'SignUp'}"
                                                                   class="btn custom-btn-search custom-employee-btn my-2 ">
                                                    <span class="custom-btn-more-text font-weight-bold">
                                                      <i class="fas fa-plus-circle"></i>
                                                      Register As Employee
                                                    </span>
                                                      </router-link>
                                                    </div>
                                                  </div>
                                                  <div class="col-lg-6">
                                                    <div class="custom-form-submit-btn">

                                                      <router-link :to="{name: 'SignUp'}"
                                                                   class="btn custom-btn-search custom-employer-btn my-2 ">
                                                        <span class="custom-btn-more-text font-weight-bold">
                                                          <i class="fas fa-plus-circle"></i>
                                                          Register As Employer
                                                        </span>
                                                      </router-link>
                                                    </div>
                                                  </div>
                                                  &lt;!&ndash;                          <div class="col-lg-2"></div>&ndash;&gt;
                                                </div>-->
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="carousel-item">
          <section class="webSection custom-landing-banner-section3">
            <div class="custom-banner-background-overlay"></div>
            <div class="custom-section">
              <div class="custom-row-wrap">
                <div class="custom-row-element">
                  <div class="custom-row-element-wrap">
                    <div class="row custom-row-section ">
                      <div class="col-lg-12 custom-column-banner-heading custom-carousel-left-z-index ">
                        <div class="custom-banner-heading">
                          <span>Lets Start Your Job </span><br>
                          <span>With <span class="custom-text-color">SheMeanWork</span></span>
                        </div>
                        <div class="custom-banner-p">
                          <p>Find Jobs, Employment and Career Opportunities</p>
                        </div>
                        <!--                        <div class="row mt-4 w3-animate-bottom">
                                                  <div class="col-lg-6">
                                                    <div class="custom-form-submit-btn">

                                                      <router-link :to="{name: 'SignUp'}"
                                                                   class="btn custom-btn-search custom-employee-btn my-2 ">
                                                    <span class="custom-btn-more-text font-weight-bold">
                                                      <i class="fas fa-plus-circle"></i>
                                                      Register As Employee
                                                    </span>
                                                      </router-link>
                                                    </div>
                                                  </div>
                                                  <div class="col-lg-6">
                                                    <div class="custom-form-submit-btn">

                                                      <router-link :to="{name: 'SignUp'}"
                                                                   class="btn custom-btn-search custom-employer-btn my-2 ">
                                                        <span class="custom-btn-more-text font-weight-bold">
                                                          <i class="fas fa-plus-circle"></i>
                                                          Register As Employer
                                                        </span>
                                                      </router-link>
                                                    </div>
                                                  </div>

                                                </div>-->
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <a class="carousel-control-prev custom-carousel-prev" href="#carouselExampleControls" role="button"
         data-slide="prev">
        <span class="carousel-control-prev-icon " aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next custom-carousel-next" href="#carouselExampleControls" role="button"
         data-slide="next">
        <span class="carousel-control-next-icon " aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>

    <!--  Content Start  -->
    <div class="custom-row-element-wrap mt-5 mb-5">
      <!--   What we do section   -->
      <div class="text-center mb-5">
        <div class="custom-widget mt-2 ">
          <h2 class=" ">What We Do</h2>
        </div>
        <div class="custom-row justify-content-center">
          <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
          <span class="ml-1 mr-1" style="color: #ea216c;font-size: 24px">
<!--            <i class="far fa-user"></i>-->
            <b-icon icon="person"></b-icon>
          </span>
          <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
        </div>

      </div>

      <div class="post-container">
        <div>
          <p class="custom-service-text">
          </p>
        </div>
      </div>

    </div>

    <!-- Contact Us Form-->
    <div class="custom-row-element-wrap mt-5 mb-5">

      <div class="text-center mb-5">
        <div class="custom-widget mt-2 ">
          <h2 class=" ">Staging Of Developing A Business Venture</h2>
        </div>
        <div class="custom-row justify-content-center">
          <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
          <span class="ml-1 mr-1" style="color: #ea216c;font-size: 24px">
            <i class="fas fa-sitemap"></i>
<!--            <b-icon icon="person"></b-icon>-->
          </span>
          <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
        </div>

      </div>
      <div class="row no-gutters ">
        <div class="col-lg-3 card custom-package-card custom-venture-card">
          <div class=" text-center p-4">

            <div class="card-header bg-transparent">
              <div class="text-center">
                <div class="custom-card-icon font-weight-bold">
                 1
                </div>
                <div>
                  <h2 class="widget-title">The Right Reasons</h2>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="">

                <p class="custom-service-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </div>
            </div>

          </div>
        </div>
        <div class="col-lg-3 card custom-package-card custom-venture-card">
          <div class="text-center p-4">
            <div class="card-header bg-transparent">
              <div class="text-center">
                <div class="custom-card-icon font-weight-bold">
                  2
                </div>
                <div>
                  <h2 class="widget-title">The Right Plan</h2>
                </div>
              </div>
            </div>

            <div class="card-body">
              <div class="">

                <p class="custom-service-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </div>
            </div>

          </div>
        </div>
        <div class="col-lg-3 card custom-package-card custom-venture-card">
          <div class="text-center p-4">
            <div class="card-header bg-transparent">
              <div class="text-center">
                <div class="custom-card-icon font-weight-bold">
                  3
                </div>
                <div>
                  <h2 class="widget-title">The Right Advice</h2>
                </div>
              </div>
            </div>

            <div class="card-body">
              <div class="">

                <p class="custom-service-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </div>
            </div>

          </div>
        </div>
        <div class="col-lg-3 card custom-package-card custom-venture-card">
          <div class="text-center p-4">
            <div class="card-header bg-transparent">
              <div class="text-center">
                <div class="custom-card-icon custom-venture-card-icon font-weight-bold">
                  4
                </div>
                <div>
                  <h2 class="widget-title">The Right Funding</h2>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="">

                <p class="custom-service-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>

    <!--   Spacer   -->
    <div class="pt-5"></div>
    <!--  Contact Us    -->
    <div>
      <div class="text-center clear-bottom">
        <router-link to="/contact-us" class="btn link-theme text-decoration-none">Contact Us</router-link>
      </div>
    </div>
    <!--   Spacer   -->
    <div class="pt-5"></div>
  </div>
</template>

<script>

// optional style for arrows & dots

import axios from 'axios'
import { UsersIcon } from 'vue-feather-icons'
// import { RlCarouselSlide } from 'vue-renderless-carousel'
export default {
  name: 'CompanyStartup',
  components: {
    // SlidesComponent,
    // RlCarouselSlide
    UsersIcon
  },
  data () {
    return {

      url: axios.defaults.baseURL,
      currentPage: 1,
      total: 1,

      list: null,
      PageOfItems: [],
      info: [],
      option: 0,

      loading: true,
      errored: false,

      componentKey: 0
    }
  },
  // carousel_latest_jobs
  methods: {

    // Rerender Component
    rerender () {
      this.componentKey += 1
    }
  },

  mounted () {

  }
}
</script>

<template>
  <div  :key="componentKey">

    <!--  Content Start  -->
    <div class="custom-row-element-wrap mt-5 mb-5">
      <div class="post-container">
        <div class="custom-widget mt-2 mb-4 border-bottom ">
          <h2 style="display: flex;" class="widget-title text-uppercase">{{ $t('Main.ContactUs') }}</h2>
        </div>
        <div>
          <form v-if="show" class="needs-validation"  :class="[!isValidated  ? '':'', isValidated ? 'was-validated':'']"
                 autocomplete="off" novalidate>
            <div class="form-group row">
              <label style="display: flex;" for="inputName" class="col-sm-2 col-form-label">
                {{$t('ContactUS.Name')}}
                <span class="text-danger">*</span>
              </label>
              <div class="col-sm-10">
                <input v-model="userModel.name" type="text" class="form-control form-control-sm custom-input-gotopage" :class="{
                              'is-invalid':
                                submitted && $v.userModel.name.$error,
                            }" id="inputName" :placeholder="  $t('ContactUS.Name')" pattern="^[a-zA-Z\s]*$">
                <div
                  v-if="submitted && !$v.userModel.name.required"
                  class="invalid-feedback"
                >
                  Name is required
                </div>
                <div
                  v-if="!$v.userModel.name.minLength"
                  class="invalid-feedback"
                >
                  Name must contain at least 3 characters
                </div>
                <div
                  v-if="!$v.userModel.name.maxLength"
                  class="invalid-feedback"
                >
                  The maximum length of 100 characters is reached
                </div>
                <div v-if="!$v.userModel.name.alpha" class="invalid-feedback">
                  <span >Invalid Full Name, Only Characters are allowed Here</span>
                </div>

              </div>
            </div>
            <div class="form-group row">
              <label style="display: flex;" for="inputAddress" class="col-sm-2 col-form-label">
                {{$t('ContactUS.Address')}}
                <span class="text-danger">*</span>
              </label>
              <div class="col-sm-10">
                <input v-model="userModel.address" type="text" class="form-control form-control-sm custom-input-gotopage" :class="{
                              'is-invalid':
                                submitted && $v.userModel.address.$error,
                            }" id="inputAddress" :placeholder="$t('ContactUS.Address')" >
                <div
                  v-if="submitted && !$v.userModel.address.required"
                  class="invalid-feedback"
                >
                  Address is required
                </div>
                <div
                  v-if="!$v.userModel.address.minLength"
                  class="invalid-feedback"
                >
                  Address must contain at least 3 characters
                </div>
                <div
                  v-if="!$v.userModel.address.maxLength"
                  class="invalid-feedback"
                >
                  The maximum length of 350 characters is reached
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label style="display: flex;" for="inputPhoneNo" class="col-sm-2 col-form-label">
                {{$t('ContactUS.Phone')}} #
                <span class="text-danger">*</span>
              </label>
              <div class="col-sm-10">
                <vue-tel-input v-bind="vueTellProps"  v-model="userModel.phone"  ></vue-tel-input>
                <input hidden v-model="userModel.phone" type="text" class="form-control form-control-sm custom-input-gotopage" :class="{
                              'is-invalid':
                                submitted && $v.userModel.phone.$error,
                            }" id="inputPhoneNo" :placeholder="$t('ContactUS.Phone')" >
                <div
                  v-if="submitted && !$v.userModel.phone.required"
                  class="invalid-feedback"
                >
                  Phone # is required
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label style="display: flex;" for="inputEmail" class="col-sm-2 col-form-label">
                {{$t('ContactUS.Email')}}
                <span class="text-danger">*</span>
              </label>
              <div class="col-sm-10">
                <input v-model="userModel.email"
                       type="email"
                       class="form-control form-control-sm custom-input-gotopage"
                       :class="{
                              'is-invalid':
                                submitted && $v.userModel.email.$error,
                            }"
                       id="inputEmail"
                       :placeholder="$t('ContactUS.Email')"

                      >
                <div
                  v-if="submitted && !$v.userModel.email.required"
                  class="invalid-feedback"
                >
                  Email is required
                </div>
                <div
                  v-if="!$v.userModel.email.email"
                  class="invalid-feedback"
                >
                  Accepts valid email addresses
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <legend style="display: flex;" class="col-form-label col-sm-2 pt-0">
                  {{$t('ContactUS.NTNObtained')}}
                  <span class="text-danger">*</span>
                </legend>
                <div class="col-sm-10">
                  <b-form-group class="border-0" :class="{
                              'is-invalid':
                                submitted && $v.userModel.ntn.$error,
                            }">
                    <b-form-radio-group

                      :options="ntnObtainedOptions"

                      v-model="userModel.ntn"
                      name="radio-inline1"

                    ></b-form-radio-group>
                  </b-form-group>
                  <div
                    v-if="submitted && !$v.userModel.ntn.required"
                    class="invalid-feedback"
                  >
                    NTN is required
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label style="display: flex;" for="inputCompanyName" class="col-sm-2 col-form-label">
                {{$t('ContactUS.CompanyName')}}
                <span class="text-danger">*</span>
              </label>
              <div class="col-sm-10">
                <input v-model="userModel.companyName" type="text" class="form-control form-control-sm custom-input-gotopage" :class="{
                              'is-invalid':
                                submitted && $v.userModel.companyName.$error,
                            }" id="inputCompanyName" :placeholder="$t('ContactUS.CompanyName')" pattern="^[a-zA-Z\s]*$">
                <div
                  v-if="submitted && !$v.userModel.companyName.required"
                  class="invalid-feedback"
                >
                  Company Name is required
                </div>
                <div
                  v-if="!$v.userModel.companyName.minLength"
                  class="invalid-feedback"
                >
                  Company Name must contain at least 3 characters
                </div>
                <div
                  v-if="!$v.userModel.companyName.maxLength"
                  class="invalid-feedback"
                >
                  The maximum length of 100 characters is reached
                </div>
                <div v-if="!$v.userModel.companyName.alpha" class="invalid-feedback">
                  <span >Invalid Company Name, Only Characters are allowed Here</span>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label style="display: flex;" for="inputWebsite" class="col-sm-2 col-form-label">{{$t('ContactUS.Website')}}</label>
              <div class="col-sm-10">
                <input v-model="userModel.webSite" type="url" class="form-control form-control-sm custom-input-gotopage" :class="{
                              'is-invalid':
                                submitted && $v.userModel.webSite.$error,
                            }" id="inputWebsite" :placeholder="$t('ContactUS.Website')" >
                <div
                  v-if="submitted && !$v.userModel.webSite.url"
                  class="invalid-feedback"
                >
                  Accepts only valid URLs.
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label style="display: flex;" for="inputMonthlyTurnover" class="col-sm-2 col-form-label">
                {{$t('ContactUS.MonthlyTurnover')}}
              </label>
              <div class="col-sm-10">
                <input v-model="userModel.monthlyTrunover" type="number" class="form-control form-control-sm custom-input-gotopage" id="inputMonthlyTurnover" :placeholder="$t('ContactUS.MonthlyTurnover')" >
              </div>
            </div>
            <div class="form-group row">
              <label  class="col-sm-2 col-form-label">
                Services Required
                <span class="text-danger">*</span>
              </label>
              <div class="col-sm-10">
                <multiselect class="custom-multiselect"  :class="{
                              'is-invalid':
                                submitted && $v.selectedServices.$error,
                            isServiceCustomMultiselect }" @input="onSelectedServices(selectedServices)" :max-height="150" v-model="selectedServices" tag-placeholder="Add this as new tag" placeholder="Search or add Service"  :options="services" :multiple="true" :taggable="true" @tag="addServiceTag" ></multiselect>
                <div
                  v-if="submitted && !$v.selectedServices.required"
                  class="invalid-feedback"
                >
                  Please select the Services Required
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label  style="display: flex;" class="col-sm-2 col-form-label">
                {{$t('ContactUS.PackagesRequired')}}
                <span class="text-danger">*</span>
              </label>
              <div class="col-sm-10">
                <multiselect class="custom-multiselect" :class="{
                              'is-invalid':
                                submitted && $v.selectedPackages.$error,
                            isPackageCustomMultiselect }" @input="onSelectedPackages(selectedPackages)" :max-height="150" v-model="selectedPackages" tag-placeholder="Add this as new tag" :placeholder="$t('ContactUS.PackagesRequired')"  :options="packages" :multiple="true" :taggable="true" @tag="addPackageTag" ></multiselect>
                <div
                  v-if="submitted && !$v.selectedPackages.required"
                  class="invalid-feedback"
                >
                  Please select the Packages Required
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label style="display: flex;" for="inputDetailedDescription" class="col-sm-2 col-form-label"> {{$t('ContactUS.DetailedDescription')}}</label>
              <div class="col-sm-10">
                <textarea v-model="userModel.detailDescription" type="text" class="form-control form-control-sm custom-input-gotopage" :class="{
                              'is-invalid':
                                submitted && $v.userModel.detailDescription.$error,
                            }" rows="3" id="inputDetailedDescription" :placeholder="$t('ContactUS.DetailedDescription')"></textarea>
              </div>
            </div>

            <div class="modal-footer">

<!--              <div v-if="showCloseButton">
                <b-button
                  id="showCloseButton"
                  class="form-control form-control-sm custom-form-btn custom-btn-gotopage"
                  type="button"
                  size="sm"
                  @click="close"
                  variant="outline-dark"
                  data-dismiss="modal"
                >Close
                </b-button>
              </div>-->
              <div v-if="showResetButton">
                <b-button
                  id="showResetButton"
                  class="form-control form-control-sm custom-form-btn custom-btn-gotopage"
                  @click="onReset"
                  size="sm"

                  variant="outline-dark"
                >{{$t('Main.Reset')}}
                </b-button>
              </div>
              <div>
                <b-button

                  v-if="showSaveButton"
                  class="form-control form-control-sm custom-form-btn custom-btn-gotopage"
                  @click="handleSubmit()"
                  size="sm"
                  variant="outline-dark"
                >{{$t('Main.Submit')}}
                </b-button>
              </div>
              <!-- <div>
                <b-button
                  v-if="showReassignButton"
                  class="form-control form-control-sm custom-form-btn custom-btn-gotopage"
                  @click="AddOrUpdate()"
                  size="sm"
                  variant="outline-dark"
                  >Reassign
                </b-button>
              </div> -->
            </div>
          </form>
        </div>

      </div>

    </div>

  </div>
</template>

<script>

// optional style for arrows & dots

import axios from 'axios'
import Multiselect from 'vue-multiselect'
import {
  required,
  minLength,
  maxLength,
  email,
  alpha,
  url,
  helpers
} from 'vuelidate/lib/validators'
// import { RlCarouselSlide } from 'vue-renderless-carousel'
export default {
  name: 'ContactUs',
  components: {
    // SlidesComponent,
    // RlCarouselSlide
    Multiselect
  },
  data () {
    return {
      submitted: false,

      ntnObtainedOptions: [
        { text: 'Yes', value: 1 },
        { text: 'No', value: 2 }
      ],

      url: axios.defaults.baseURL,
      currentPage: 1,
      total: 1,

      list: null,
      PageOfItems: [],
      info: [],
      option: 0,

      loading: true,
      errored: false,

      vueTellProps: {
        mode: 'international',
        defaultCountry: 'PK',

        preferredCountries: ['PK'],
        onlyCountries: [],
        ignoredCountries: [],

        wrapperClasses: '',
        inputClasses: '',

        inputOptions: {
          showDialCode: true,
          name: 'telephone',
          autocomplete: 'off',
          placeholder: 'Enter a phone number',
          required: true,
          type: 'tel',
          maxlength: 15

        },
        validCharactersOnly: true
      },

      isServiceCustomMultiselect: '',
      isPackageCustomMultiselect: '',
      selectedServices: [],
      services: [],
      servicesModel: {
        PageSize: 0,
        PageNumber: 1,
        Text: ''
      },
      selectedPackages: [],
      packages: [],
      packagesModel: {
        PageSize: 0,
        PageNumber: 1,
        Text: ''
      },
      // form
      show: true,

      userModel: {
        name: '',
        address: '',
        phone: '',
        email: '',
        ntn: 1,
        companyName: '',
        webSite: '',
        monthlyTrunover: '',
        servicesRequired: '',
        packageRequired: '',
        detailDescription: ''
      },

      showCloseButton: true,
      showResetButton: true,

      showSaveButton: true,
      // Form Validation Bool
      isValidated: false,

      componentKey: 0
    }
  },

  validations: {
    userModel: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(100),
        alpha: helpers.regex('alpha', /^[a-zA-Z\s]*$/)
      },
      address: { required, minLength: minLength(3), maxLength: maxLength(350) },
      phone: { required },
      email: {
        required,
        email
      },
      ntn: {
        required
      },

      companyName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(100),
        alpha: helpers.regex('alpha', /^[a-zA-Z\s]*$/)
      },
      webSite: { url },
      monthlyTrunover: { required },
      detailDescription: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(2000)
      }
    },

    selectedServices: { required },
    selectedPackages: { required }
  },
  methods: {

    getServicesList () {
      this.rerender()

      this.services = []
      axios.post('/api/Shehri/GetVentureServices', this.servicesModel)
        .then(res => {
          if (res.status != '200') {
            console.log('error.....')
            const error = new Error('Failed to fetch!')
            return error
          }

          const list = res.data.data

          for (const data in list) {
            this.services.push(list[data].title)
          }
          // this.services = list.slice().reverse();

          console.log('ventureher services : ', this.services)
        }).catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => {
          if (this.errored) {
            console.log('Error : Sorry there is no response')
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          }
        })
    },

    getPackagesList () {
      this.rerender()

      this.packages = []
      axios.post('/api/Shehri/GetVenturePackages', this.packagesModel)
        .then(res => {
          if (res.status != '200') {
            console.log('error.....')
            const error = new Error('Failed to fetch!')
            return error
          }
          const list = res.data.data
          // this.packages = list.slice().reverse();
          for (const data in list) {
            this.packages.push(list[data].title)
          }
          console.log('ventureher packages : ', this.packages)
          // let currentDate = moment(String(new Date())).format('YYYY-MM-DD')

          /* for (let post in this.list){
            console.log("this.list[post]: ",this.list[post])
            if(this.list[post].postType === 1){
              this.featuredPosts.push(this.list[post]);
            }

          }

          console.log("Featured Jobs : ", this.featuredPosts);
          */
        }).catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => {
          if (this.errored) {
            console.log('Error : Sorry there is no response')
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          }
        })
    },
    /* AddOrUpdate(){
      this.isValidated = true;
    }, */
    submitForm () {
      this.info = []

      if (
        this.userModel.name != '' &&
        this.userModel.address != '' &&
        this.userModel.phone != '' &&
        this.userModel.email != '' &&

        this.userModel.companyName != '' &&
        this.selectedServices != [] &&
        this.selectedPackages != []
      ) {
        this.userModel.ntn = this.userModel.ntn == 1 ? 'Yes' : this.userModel.ntn == 2 ? 'No' : 'Yes'

        this.userModel.servicesRequired = String(this.selectedServices)
        console.log('servicesRequired = ', this.userModel.servicesRequired)
        this.userModel.packageRequired = String(this.selectedPackages)
        console.log('packageRequired = ', this.userModel.packageRequired)

        console.log('Employee : ', this.userModel)
        axios
          .post('/api/Shehri/VentureContactUs', this.userModel)
          .then(res => {
            // code that we will 'try' to run
            this.info = res.data

            console.log('Add Response portion')

            console.log('response : ', this.info)
          })
          .catch(error => {
            console.log('Errorrrrrr : ', error)
            this.errored = true
          })
          .finally(() => {
            if (this.errored) {
              console.log('Error : Sorry there is no response')
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            } else {
              this.$bvToast.toast('Thanks for contacting us! We will be in touch with you shortly.', {
                toaster: 'b-toaster-top-center',
                variant: 'success',

                solid: true
              })
            }
          })
      } else {
        this.isValidated = true
        this.$bvToast.toast('Please Fill all required fields', {
          toaster: 'b-toaster-top-center',
          variant: 'danger',
          solid: true
        })
      }
    },
    handleSubmit () {
      this.submitted = true
      /* this.userModel.name = this.userModel.name ==="" ? null : this.userModel.name ;
      this.userModel.address = this.userModel.address ==="" ? null : this.userModel.address ;
      this.userModel.phone = this.userModel.phone ==="" ? null : this.userModel.phone;
      this.userModel.email = this.userModel.email ==="" ? null : this.userModel.email;
      this.userModel.ntn = this.userModel.ntn ==="" ? null : this.userModel.ntn;
      this.userModel.companyName = this.userModel.companyName ==="" ? null : this.userModel.companyName ;
      this.userModel.webSite = this.userModel.webSite ==="" ? null : this.userModel.webSite ;
      this.userModel.monthlyTrunover = this.userModel.monthlyTrunover ==="" ? null : this.userModel.monthlyTrunover ;
      this.userModel.detailDescription = this.userModel.detailDescription ==="" ? null : this.userModel.detailDescription ; */

      this.$v.$touch()
      if (this.$v.$invalid) {
        console.log('invalid')
        return
      }
      this.submitForm()
    },

    addServiceTag (newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }
      this.services.push(tag)

      this.selectedServices.push(tag)
    },
    addPackageTag (newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }
      this.packages.push(tag)

      this.selectedPackages.push(tag)
    },
    onSelectedServices (sl) {
      console.log('selected Services : ', sl)
      if (sl.length == 0) {
        this.isServiceCustomMultiselect = 'custom-multiselect-invalid'
      } else {
        this.isServiceCustomMultiselect = 'custom-multiselect-valid'
      }
    },
    onSelectedPackages (sl) {
      console.log('selected packages : ', sl)
      if (sl.length == 0) {
        this.isPackageCustomMultiselect = 'custom-multiselect-invalid'
      } else {
        this.isPackageCustomMultiselect = 'custom-multiselect-valid'
      }
    },
    // Rerender Component
    rerender () {
      this.componentKey += 1
    },
    onReset () {
      this.userModel.name = ''
      this.userModel.address = ''
      this.userModel.phone = '+92'
      this.userModel.email = ''
      this.userModel.ntn = 1
      this.userModel.companyName = ''
      this.userModel.webSite = ''
      this.userModel.monthlyTrunover = ''
      this.userModel.detailDescription = ''
      this.selectedServices = []
      this.selectedPackages = []
    }
  },

  mounted () {
    this.getServicesList()
    this.getPackagesList()
  }
}
</script>
<style>
.custom-multiselect{
  font-size: .875rem;
  border: 1px solid #ced4da;

  border-radius: 0.2rem;

}
.custom-multiselect-valid{
  border: 1px solid #28a745;

  border-radius: 0.2rem;

}
.custom-multiselect-invalid{
  border: 1px solid #dc3545;
  border-radius: 0.2rem;

}
</style>

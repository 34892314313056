<template>
  <header class="custom-mainmenu sticky-top">

    <div class=""></div>
    <div class="">
      <nav class="custom-navbar navbar navbar-expand-lg navbar-light">

        <div></div>

        <div
          class="navbar-collapse custom-multi-navbar ml-3 mr-3"
          id="headerSupportedContent"
        >

          <div  class="navbar mr-auto w-100 pl-0 pr-0">
            <ul class="navbar-nav mr-auto">
              <li class="nav-item mr-4">
                <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" :to="{ name: 'Home'}">
                  {{ $t('Main.Home') }}
                </router-link
                >
              </li>
<!--              <li class="nav-item dropdown mr-5">
                <a class="nav-link custom-menu-link py-1 px-0 mr-3 dropdown-toggle" href="#" id="categoriesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Company Startup
                </a>
                <div class="dropdown-menu w3-animate-opacity" aria-labelledby="categoriesDropdown">
                  <router-link class="dropdown-item custom-menu-link" to="/company-startup">Start Up Ideas</router-link>
                  <router-link class="dropdown-item custom-menu-link" to="/accelerate-your-business">Accelerate Your Business</router-link>

                </div>
              </li>-->
              <li class="nav-item mr-4">
                <router-link class="nav-link custom-menu-link py-1 px-0 mr-3"
                             :to="{ name: 'PartnersAndFunding',
                              params: { type: 1, searchString:0 }
                            }">
                   {{ $t('Main.BusinessServices') }}
                </router-link>
              </li>
              <li class="nav-item mr-4">
                <router-link class="nav-link custom-menu-link py-1 px-0 mr-3"
                             :to="{ name: 'PartnersAndFunding',
                              params: { type: 2, searchString:0 }
                            }">
                 {{ $t('Main.Investment') }}
                </router-link>
              </li>
              <li class="nav-item mr-4">
                <router-link class="nav-link custom-menu-link py-1 px-0 mr-3"
                             :to="{ name: 'PartnersAndFunding',
                              params: { type: 4, searchString:0 }
                            }">
                 {{ $t('Main.ExpertPanel') }}
                </router-link>
              </li>
              <li class="nav-item mr-4">
                <a class="nav-link custom-menu-link py-1 px-0 mr-3" href="https://www.harshehar.com/#/social-register" target="_blank">
                  {{ $t('Main.SocialRegister') }}
                </a>
              </li>
              <li class="nav-item mr-4">
                <router-link
                  class="nav-link custom-menu-link py-1 px-0 mr-3"
                  to="/contact-us"
                >
                {{ $t('Main.ContactUs') }}
                </router-link>
              </li>

            </ul>
            <div class="" style="direction: ltr;">
              <div class="input-group custom-search-input">
                <div class="input-group-prepend">
                    <span
                      class="input-group-text custom-input-group-icon"
                      id="basic-addon1"
                    ><b-icon icon="Search"></b-icon
                    ></span>
                </div>
                <input
                  type="text"
                  class="form-control custom-filter-input form-control-sm"
                  :placeholder=" $t('Main.Search')"
                  v-model="parameters.searchString"
                  @keydown.enter.prevent = "setSearchString"
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                />
              </div>
            </div>
          </div>

          <!--            <template v-if="$auth.check()" >
                        &lt;!&ndash; <ul v-if="$auth.check()" class="navbar-nav mr-auto"> &ndash;&gt;

                           <ul  v-if="$auth.user().user.role == 'Employer'" class="navbar-nav mr-auto">
                        <li class="nav-item mr-2">
                          <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/employer-dashboard">
                            Dashboard
                          </router-link
                          >
                        </li>
                        <li class="nav-item dropdown mr-2">
                          <a class="nav-link custom-menu-link py-1 px-0 mr-3 dropdown-toggle" href="#" id="myJobsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            My Jobs
                          </a>
                          <div class="dropdown-menu w3-animate-opacity" aria-labelledby="myJobsDropdown">
                            <router-link class="dropdown-item" to="/employer-jobs">All Listed Jobs</router-link>
                            <router-link class="dropdown-item" to="/employer-candidates/0">All Employee Profile</router-link>

                          </div>
                        </li>

          &lt;!&ndash;              <li class="nav-item dropdown mr-2">
                          <a class="nav-link custom-menu-link py-1 px-0 mr-3 dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            CV Folder
                          </a>
                          <div class="dropdown-menu w3-animate-opacity" aria-labelledby="navbarDropdown">
                            <router-link class="dropdown-item" to="/employer-jobs">Description of Jobs</router-link>
                            <router-link class="dropdown-item" to="/employer-candidates">CV via Unassisted Hiring</router-link>
                            <router-link class="dropdown-item" to="/employer-candidates">CV via Assisted Hiring</router-link>
                            <router-link class="dropdown-item" to="/employer-candidates">CV upload within a Department</router-link>

                          </div>
                        </li>&ndash;&gt;

                        <li class="nav-item dropdown mr-2">
                          <a class="nav-link custom-menu-link py-1 px-0 mr-3 dropdown-toggle" href="#" id="myToolsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            My Tools
                          </a>
                          <div class="dropdown-menu w3-animate-opacity" aria-labelledby="myToolsDropdown">
          &lt;!&ndash;                  <router-link class="dropdown-item" to="/employer-analytics">Company</router-link>&ndash;&gt;
          &lt;!&ndash;                  <router-link class="dropdown-item" to="/employer-about-us">Company Public Profile</router-link>&ndash;&gt;
          &lt;!&ndash;                  <router-link class="dropdown-item" to="/employer-teamlist">Department</router-link>&ndash;&gt;

                            <router-link class="dropdown-item" to="/employer-profile">Update Company Profile</router-link>
                            <router-link class="dropdown-item" to="/employer-teamlist">Team Members</router-link>
                            <router-link class="dropdown-item" to="/change-password">Account Settings</router-link>
                            <router-link class="dropdown-item" to="/employer-accountsetting">Alerts / Notifications </router-link>
                            <router-link class="dropdown-item" to="/employer-packagehistory">Purchase History </router-link>
                            <router-link class="dropdown-item" to="/employer-package">Packages </router-link>

                          </div>
                        </li>
                        &lt;!&ndash;              <li class="nav-item mr-2">
                                        <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/">
                                          My Account
                                        </router-link>

                                      </li>&ndash;&gt;

                        &lt;!&ndash; <li class="nav-item mr-2">
                          <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/">
                            Employer
                          </router-link>
                        </li> &ndash;&gt;

                       <li class="nav-item mr-2">
                         <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/cvsearch">
                           CV Search
                         </router-link>
                       </li>
                        <li class="nav-item mr-2">
                          <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/employer-about-us/0">
                            About Us
                          </router-link>
                        </li>
                         <li class="nav-item mr-2">
                           <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/employer-postjob">
                             Post A Job
                           </router-link>
                         </li>
                      </ul>
                          <ul v-else class="navbar-nav mr-auto">
                        <li class="nav-item mr-2">
                          <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/employee-dashboard">
                            Dashboard
                          </router-link
                          >
                        </li>
                        <li class="nav-item dropdown mr-2">
                          <a class="nav-link custom-menu-link py-1 px-0 mr-3 dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            My Account
                          </a>
                          <div class="dropdown-menu w3-animate-opacity" aria-labelledby="navbarDropdown">
                            <router-link class="dropdown-item" to="/employee-profile">Profile</router-link>
                            <router-link class="dropdown-item" to="/employee-jobs">Applications</router-link>
                            <router-link class="dropdown-item" to="/purchase-history">Purchase History</router-link>
                            <router-link class="dropdown-item" :to="{ name: 'ChangePassword'}">Change Password</router-link>

                          </div>
                        </li>
                        &lt;!&ndash;              <li class="nav-item mr-2">
                                        <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/">
                                          My Account
                                        </router-link>

                                      </li>&ndash;&gt;
                        <li class="nav-item mr-2">
                          <router-link
                              class="nav-link custom-menu-link py-1 px-0 mr-3"
                              to="/all-jobs"
                          >
                            Find Job
                          </router-link>
                        </li>
                        <li class="nav-item mr-2">
                          <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/">
                            Employer
                          </router-link>
                        </li>

                        <li class="nav-item mr-2">
                          <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/about-us">
                            About Us
                          </router-link>
                        </li>
                        <li class="nav-item mr-2">
                          <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/add-profile-detail">
                            Complete Your Profile
                          </router-link>
                        </li>
                      </ul>
                    </template>-->
        </div>

        <div></div>
      </nav>
    </div>

  </header>
</template>

<script>

export default {
  name: 'MainMenu',
  components: {

  },
  data () {
    return {
      status: 0,

      // bool
      loggedOut: true,
      loggedIn: false,
      // userName: this.$auth.user().fullName,
      // email: this.$auth.user().email,
      // phoneNo:this.$auth.user().phoneNumber,
      // mailtoEmail:"mailto:"+this.$auth.user().email,

      // url:axios.defaults.baseURL,

      // searchString:"0"
      parameters: {
        type: 0,
        searchString: ''
      }

    }
  },
  mounted () {

    // this.getAll();

    // this.$nextTick(() => {
    //   // Change top navbar on scroll
    //   $(window).on("scroll", function() {
    //     if($(window).scrollTop() > 100) {
    //       $("#ad-banner-id").addClass("active");
    //     } else {
    //       $("#ad-banner-id").removeClass("active");
    //     }
    //   });
    // });

  },

  methods: {

    setSearchString () {
      console.log('type', this.$route.name)

      if (this.$route.name == 'PartnersAndFunding') {
        this.$router.push(
          { name: 'PartnersAndFunding', params: { type: this.$route.params.type, searchString: this.parameters.searchString } })
      } else {
        this.$router.push(
          { name: 'PartnersAndFunding', params: { type: 0, searchString: this.parameters.searchString } })
      }
    }

  }
}
</script>

<style>
/*#ad-banner-id{
  transition: all 0.2s ease-in-out;
  display: block;
}
#ad-banner-id.active{
  display: none;
  transition: all 0.2s ease-in-out;
}*/
</style>

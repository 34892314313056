import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../pages/Home'

import ContactUs from '../pages/ContactUs'
import WhatWeDo from '../pages/WhatWeDo'
import AccelerateYourBusiness from '../pages/AccelerateYourBusiness'
import CompanyStartup from '../pages/CompanyStartup'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },

  {
    path: '/contact-us',
    name: 'ContactUs',
    component: ContactUs
  },
  {
    path: '/whatwedo',
    name: 'WhatWeDo',
    component: WhatWeDo
  },
  {
    path: '/accelerate-your-business',
    name: 'AccelerateYourBusiness',
    component: AccelerateYourBusiness
  },
  {
    path: '/company-startup',
    name: 'CompanyStartup',
    component: CompanyStartup
  },
  {
    path: '/detail/:id',
    name: 'Detail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Detail" */ '../pages/whyVentureHer/Detail.vue')
  },
  {
    path: '/all-services',
    name: 'AllServices',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "AllServices" */ '../pages/services/AllServices.vue')
  },
  {
    path: '/service-detail/:id',
    name: 'ServiceDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ServiceDetail" */ '../pages/services/ServiceDetail.vue')
  },
  {
    path: '/all-packages',
    name: 'AllPackages',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "AllPackages" */ '../pages/packages/AllPackages.vue')
  },
  {
    path: '/package-detail/:id',
    name: 'PackageDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "PackageDetail" */ '../pages/packages/PackageDetail.vue')
  },
  {
    path: '/partners-and-funding/:type,:searchString',
    name: 'PartnersAndFunding',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "PartnersAndFunding" */ '../pages/partnersAndFunding/PartnersAndFunding.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/404',
    name: 'PageNotFound',
    component: () => import('../pages/PageNotFound')
  },
  { path: '*', name: 'redirectPageNotFound', redirect: '/404' }
]

const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    console.log('savedPosition : ', savedPosition)
    if (savedPosition) {
      return { x: 0, y: 0 }
    } else {
      const position = {}

      if (to.hash) {
        position.selector = to.hash
        position.offset = { y: 120 }
        position.behavior = 'smooth'
        if (document.querySelector(to.hash)) {
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              resolve(position)
            }, 2500)
          })
        }
        /* {
          selector: to.hash,
          behavior: 'smooth',

        } */
      }
      return { x: 0, y: 0 }
    }
  }
})

export default router

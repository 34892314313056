<template>
  <div
    class="custom-sidebar w3-sidebar w3-bar-block w3-animate-left"
    id="mySidebar" style="direction: ltr;"
  >
    <div
      id="sidebar-tabs"
      class="nav nav-tabs custom-sidebar-nav-tabs"
      role="tablist"
    >
      <router-link
        to="/"
        class="w3-bar-item w3-button custom-bar-item custom-active"
      >
        <div class="custom-sidebar-heading" @click="w3_close">
          <div class="custom-row">
            <div class="custom-sidebar-icons">
              <b-icon icon="house-door"></b-icon>
            </div>
            <div class="custom-sidebar-text">
              <span>{{ $t('Main.Home') }}</span>
            </div>
          </div>
        </div>
      </router-link>
      <router-link
        :to="{ name: 'PartnersAndFunding',
                              params: { type: 1, searchString:0 }
                            }"
        class="w3-bar-item w3-button custom-bar-item custom-active"
      >
        <div class="custom-sidebar-heading" @click="w3_close">
          <div class="custom-row">
            <div class="custom-sidebar-icons">
              <b-icon icon="person-check"></b-icon>
            </div>
            <div class="custom-sidebar-text">
              <span> {{ $t('Main.BusinessServices') }}</span>
            </div>
          </div>
        </div>
      </router-link>
      <router-link
        :to="{ name: 'PartnersAndFunding',
                              params: { type: 2, searchString:0 }
                            }"
        class="w3-bar-item w3-button custom-bar-item custom-active"
      >
        <div class="custom-sidebar-heading" @click="w3_close">
          <div class="custom-row">
            <div class="custom-sidebar-icons">
              <b-icon icon="grid"></b-icon>
            </div>
            <div class="custom-sidebar-text">
              <span>  {{ $t('Main.Investment') }}</span>
            </div>
          </div>
        </div>
      </router-link>
      <router-link
        :to="{ name: 'PartnersAndFunding',
                              params: { type: 4, searchString:0 }
                            }"
        class="w3-bar-item w3-button custom-bar-item custom-active"
      >
        <div class="custom-sidebar-heading" @click="w3_close">
          <div class="custom-row">
            <div class="custom-sidebar-icons">
              <b-icon icon="grid"></b-icon>
            </div>
            <div class="custom-sidebar-text">
              <span>  {{ $t('Main.ExpertPanel') }}</span>
            </div>
          </div>
        </div>
      </router-link>
      <a
        href="https://harshehar.com/#/directory" target="_blank"
          class="w3-bar-item w3-button custom-bar-item custom-active"
      >
        <div class="custom-sidebar-heading" @click="w3_close">
          <div class="custom-row">
            <div class="custom-sidebar-icons">
              <b-icon icon="grid"></b-icon>
            </div>
            <div class="custom-sidebar-text">
              <span>  {{ $t('Main.SocialRegister') }}</span>
            </div>
          </div>
        </div>
      </a>
      <router-link
          to="/contact-us"
          class="w3-bar-item w3-button custom-bar-item custom-active"
      >
        <div class="custom-sidebar-heading" @click="w3_close">
          <div class="custom-row">
            <div class="custom-sidebar-icons">
              <b-icon icon="grid"></b-icon>
            </div>
            <div class="custom-sidebar-text">
              <span>  {{ $t('Main.ContactUs') }}</span>
            </div>
          </div>
        </div>
      </router-link>

    </div>
  </div>
</template>

<script>
export default {
  name: 'Sidebar',
  methods: {
    w3_close () {
      document.getElementById('mySidebar').style.display = 'none'

      document.getElementById('btn-open-sidebar').style.display = 'block'
      document.getElementById('btn-close-sidebar').style.display = 'none'
      console.log('clicked')
    }
  }
}
</script>

<style></style>
